import {Injectable} from '@angular/core';
import {JourneysAssignmentSummaryDto} from '../models/journeys-assignment-summary.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BookingOptimizationSearchCriteria} from '../models/booking-optimization-search.model';
import {JourneysAssignmentReportDto} from '../models/journey-assignment-report.model';

@Injectable()
export class JourneyService {

    constructor(private http: HttpClient) {}

    public findJourneysAssignmentSummary(criteria: BookingOptimizationSearchCriteria): Observable<JourneysAssignmentSummaryDto> {
        const params = criteria.toSearchParams();
        return this.http.get<JourneysAssignmentSummaryDto>('api/journey/assignment/summary', {params})
    }

    public createReport(date: string): Observable<JourneysAssignmentReportDto> {
        const params = new HttpParams().append('date', date);
        return this.http.post<JourneysAssignmentSummaryDto>('api/journey/assignment/report', {}, {params})
    }

    public sendReport(date: string): Observable<boolean> {
        const params = new HttpParams().append('date', date);
        return this.http.post<boolean>('api/journey/assignment/report/send', {}, {params})
    }

    public getReport(date: string): Observable<JourneysAssignmentReportDto> {
        return this.http.get<JourneysAssignmentSummaryDto>(`api/journey/assignment/report/${date}`);
    }
}
