import { Component, Input } from '@angular/core';
import { Passenger } from '../../../models/passenger.model';

@Component({
    selector: 'booking-passengers',
    templateUrl: './booking-passengers.component.html',
    styleUrls: ['./booking-passengers.component.scss']
})
export class BookingPassengersComponent {

    @Input() passengers: Passenger[];

}
