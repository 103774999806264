import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import * as moment_ from 'moment';

const moment = moment_;

@Directive({
    selector: '[afterDateValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: AfterDateDirective, multi: true}]
})
export class AfterDateDirective implements Validator {
    @Input('afterDateValidator') minDate = moment();

    validate(control: AbstractControl): { [key: string]: any } | null {
        const error = moment(control.value).isBefore(this.minDate);
        return error ? {afterDate: {value: control.value}} : null;
    }
}
