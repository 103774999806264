import { Component, forwardRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeSlotSelectComponent),
    multi: true
};

@Component({
    selector: 'time-slot-select',
    templateUrl: './time-slot-select.component.html',
    styleUrls: ['./time-slot-select.component.scss'],
    providers: [VALUE_ACCESSOR],
    host: {class: 'grid-x align-middle grid-padding-x'}
})
export class TimeSlotSelectComponent implements OnInit, ControlValueAccessor {

    public timeSlot: TimeSlot = new TimeSlot('00:00', '24:00');
    public startList: string[];
    public endList: string[];
    public disabled: boolean;

    private defaultList: string[];

    ngOnInit() {
        this.defaultList = [];
        for (let i = 0; i < 25; i++) {
            this.defaultList.push(String('0' + i).slice(-2) + ':00');
        }
        this.startList = _.cloneDeep(this.defaultList);
        this.startList.pop(); // remove 24:00
        this.endList = this.defaultList;
    }

    onChange = (value: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        if (obj !== null) {
            this.timeSlot = obj;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

export class TimeSlot {
    start: string;
    end: string;

    constructor(start: string, end: string) {
        this.start = start;
        this.end = end;
    }
}
