import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class EnumService {

    constructor(private http: HttpClient) {}

    public fetchValues(key: string, params?: HttpParams): Observable<string[]> {
        return this.http.get<string[]>(`api/enum/${key}`, {params});
    }
}
