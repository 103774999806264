import {Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {FileRef} from '../../../models/file-ref.model';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FilePickerComponent),
    multi: true
};

@Component({
    selector: 'file-picker',
    exportAs: 'filePicker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class FilePickerComponent implements ControlValueAccessor {

    @ViewChild('fileInput')
    public fileInput: ElementRef;

    @Input() public showDropBtn: boolean = false;
    @Input() public accept: string = '';
    public disabled: boolean;
    public fileName: string;

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onFilePicked(event: any): void {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.onChange(new FileRef(file));
            this.fileName = file.name;
        }
    }

    public deleteFile(): void {
        if (this.fileInput) {
            this.onChange(null);
            this.fileName = null;
            this.fileInput.nativeElement.value = '';
        }
    }
}
