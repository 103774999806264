import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { KeycloakPublicClientConfigModel } from '../domain/keycloak-public-client-config.model';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

declare const Keycloak: any;

@Injectable()
export class KeycloakService {

    private client: any;
    private loggedIn: boolean;

    constructor(private configService: ConfigService) {}

    public init(): Observable<string> {
        return this.configService
            .fetchKeycloakClientConfig()
            .pipe(
                switchMap(config => this.buildClient(config)),
                tap(client => console.log('keycloak client initialized !')),
                tap(client => this.client = client),
                switchMap(client => this.getToken()),
                catchError(client => of(null))
            )
    }

    public getToken(): Observable<string> {
        return Observable.create(subscriber => {
            this.client
                .updateToken(5)
                .success(() => {
                    this.loggedIn = true;
                    subscriber.next(this.client.token);
                })
                .error((e) => subscriber.error(e));
        });
    }

    public isLoggedIn(): boolean {
        return this.loggedIn;
    }

    public login(): Observable<any> {
        return Observable.create(subscriber => {
            this.client
                .login()
                .success(() => subscriber.next())
                .error(() => subscriber.error());
        });
    }

    public logout(): Observable<any> {
        return Observable.create(subscriber => {
            this.client
                .logout({redirectUri: document.baseURI})
                .success(() => {
                    this.loggedIn = false;
                    subscriber.next();
                });
        });
    }

    private buildClient(config: KeycloakPublicClientConfigModel): Observable<any> {
        const keycloakClient = Keycloak({
            url: config.authServerBaseUrl,
            realm: config.realm,
            clientId: config.resource,
            'ssl-required': 'external',
            'public-client': true
        });
        return Observable.create(subscriber => {
            keycloakClient
                .init({checkLoginIframe: false, onLoad: 'login-required', responseMode: 'query'})
                .success(() => subscriber.next(keycloakClient))
                .error(e => subscriber.error(e));
        });
    }
}
