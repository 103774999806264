import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Service} from './service';
import {plainToClass} from 'class-transformer';
import {LineDto} from '../models/line.model';

@Injectable()
export class LineService extends Service {

    constructor(private http: HttpClient) {
        super();
    }

    public findAllLines(): Observable<LineDto[]> {
        return this.http
            .get<LineDto[]>(`api/lines`)
            .pipe(map(dtos => plainToClass(LineDto, dtos)));
    }

}
