import {PostalAddressType} from 'base-lib';
import {EnrichedJourneyDto} from './shuttle-journey.model';

export type JourneyDirection = string;

export class JourneyDto {
    id: string;
    name: string;
    lineId: string;
    lineName: string;
    vehicleJourneyAtStops: JourneyStopDto[];
}

export class JourneyStopDto {
    stopPointId: string;
    stopAreaId: string;
    stopAreaName: string;
    registrationNumber: string;
    departureTime: string;
    arrivalTime: string;
    exactTime: boolean;
    position: string;
    type: PostalAddressType;
}

export class MatchingJourneyDto {
    journey: EnrichedJourneyDto;
    departureStop: JourneyStopDto;
    arrivalStop: JourneyStopDto;
    arrivalDistance: number;
    departureDistance: number;

    private static formatDistance(distance: number) {
        return distance >= 1000 ? `${(distance / 1000).toFixed(0)}km` : `${distance.toFixed(0)}m`;
    }

    public formatArrivalDistance() {
        return MatchingJourneyDto.formatDistance(this.arrivalDistance)
    }
    public formatDepartureDistance() {
        return MatchingJourneyDto.formatDistance(this.departureDistance)
    }
}
