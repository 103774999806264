import { ModuleWithProviders, NgModule } from '@angular/core';
import { AlertService } from './services/alert.service';
import { EnumService } from './services/enum.service';
import { FileService } from './services/file.service';
import { NotificationService } from './services/notification.service';
import { RequestService } from './services/request.service';
import { CheckboxComponent } from './components/common/checkbox/checkbox.component';
import { DatePickerComponent } from './components/common/date-picker/date-picker.component';
import { FilePickerComponent } from './components/common/file-picker/file-picker.component';
import { RadioComponent } from './components/common/radio/radio.component';
import { SpacerComponent } from './components/common/spacer/spacer.component';
import { StepperComponent } from './components/common/stepper/stepper.component';
import { WebcamSnapshotComponent } from './components/common/webcam-snapshot/webcam-snapshot.component';
import { StepComponent } from './components/common/stepper/step/step.component';
import { ButtonProcessingDirective } from './components/common/button-processing.directive';
import { I18nBooleanPipe } from './components/common/i18n-boolean.pipe';
import { WebcamModule } from 'ngx-webcam';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from './components/common/time-picker/time-picker.component';
import { ComboBoxComponent } from './components/common/combo-box/combo-box.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiDatePickerComponent } from './components/common/multi-date-picker/multi-date-picker.component';
import { MultiSelectComboBoxComponent } from './components/common/multi-select-combo-box/multi-select-combo-box.component';
import { TimeSlotSelectComponent } from './components/common/time-slot-select/time-slot-select.component';
import { TimeSlotSelectListComponent } from './components/common/time-slot-select/time-slot-select-list/time-slot-select-list.component';
import { MultiDateCalendarsPickerComponent } from './components/common/multi-date-calendars-picker/multi-date-calendars-picker.component';
import { MonthPickerComponent } from './components/common/month-picker/month-picker.component';
import { PostalAddressSelectComponent } from './components/common/postal-address-select/postal-address-select.component';
import { PostalAddressService } from './services/postal-address.service';
import { ExternalServicesService } from './services/external-services.service';
import { ExternalServiceLinkDirective } from './components/common/external-service-link.directive';
import { PhoneNumberInputComponent } from './components/common/phone-number-input/phone-number-input.component';
import { CountryService } from './services/country.service';
import { MapDirective } from './components/common/map.directive';
import { MapComponent } from './components/common/map/map.component';
import { InvalidFeedbackComponent } from './components/common/invalid-feedback/invalid-feedback.component';
import { BeforeDateDirective } from './components/common/validators/date/before-date.directive';
import { ValidDateDirective } from './components/common/validators/date/valid-date.directive';
import { PhoneNumberDirective } from './components/common/validators/phone/phone-number.directive';
import { AfterDateDirective } from './components/common/validators/date/after-date.directive';
import { RequiredFormElementDirective } from './components/common/form-element.directive';
import { ConfigService } from './services/config.service';
import { BusinessValidationErrorsComponent } from './components/common/business-validation-errors/business-validation-errors.component';

const components: any[] = [
    CheckboxComponent,
    DatePickerComponent,
    MonthPickerComponent,
    MultiDatePickerComponent,
    TimePickerComponent,
    FilePickerComponent,
    RadioComponent,
    SpacerComponent,
    StepperComponent,
    StepComponent,
    WebcamSnapshotComponent,
    ComboBoxComponent,
    MultiSelectComboBoxComponent,
    TimeSlotSelectComponent,
    TimeSlotSelectListComponent,
    MultiDateCalendarsPickerComponent,
    PostalAddressSelectComponent,
    PhoneNumberInputComponent,
    MapComponent,
    InvalidFeedbackComponent,
    BusinessValidationErrorsComponent
];

const directives: any[] = [
    ButtonProcessingDirective,
    RequiredFormElementDirective,
    ExternalServiceLinkDirective,
    MapDirective,
    BeforeDateDirective,
    AfterDateDirective,
    PhoneNumberDirective,
    ValidDateDirective
];

const pipes: any[] = [
    I18nBooleanPipe
];

const services: any[] = [
    AlertService,
    EnumService,
    FileService,
    NotificationService,
    RequestService,
    PostalAddressService,
    ExternalServicesService,
    CountryService,
    ConfigService
];

@NgModule({
    declarations: [components, directives, pipes],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        ToastrModule,
        DpDatePickerModule,
        NgSelectModule,
        WebcamModule
    ],
    exports: [components, directives, pipes]
})
export class BaseLibModule {

    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: BaseLibModule,
            providers: [services]
        };
    }

    public static forChild(): ModuleWithProviders {
        return {
            ngModule: BaseLibModule
        };
    }
}
