import {EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {SearchCriteria} from '../../models/search.model';

export abstract class AbstractSearchBarComponent<R, C extends SearchCriteria> implements OnInit {

    @Output() resultsChange: EventEmitter<R[]> = new EventEmitter<R[]>();
    @Output() criteriaChange: EventEmitter<C> = new EventEmitter<C>();
    @ViewChild(NgForm) form: NgForm;

    protected constructor(protected route: ActivatedRoute, protected router: Router, public criteria: C) {}

    ngOnInit(): void {
        this.clearSearch();
        this.route.queryParamMap
            .pipe(take(1))
            .subscribe(queryParams => this.fromQueryParamsToCriteria(queryParams));
        this.form.form.valueChanges
            .subscribe(value => this.fromCriteriaToQueryParams(this.criteria));
    }

    protected fromQueryParamsToCriteria(queryParams: ParamMap): void {
        queryParams.keys
            .forEach(key => {
                let value: any = queryParams.get(key);
                if (value === 'true') {
                    value = true;
                } else if (value === 'false') {
                    value = false;
                }
                this.criteria[key] = value;
            });
        this.search();
    }

    protected fromCriteriaToQueryParams(criteria: C): void {
        this.router.navigate([], {
            queryParams: criteria.toQueryParams(),
            replaceUrl: true
        });
    }

    public abstract search(): void;

    public clearSearch(): void {}
}
