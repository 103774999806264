import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JourneyStopDto} from '../../../models/journey.model';
import * as _ from 'lodash';
import {BookingAssignmentDto} from '../../../models/booking-assignment.model';
import {BookingService} from '../../../services/booking.service';
import {NotificationService} from 'base-lib';

@Component({
    selector: 'booking-assignment',
    templateUrl: './booking-assignment.component.html',
    styleUrls: ['./booking-assignment.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class BookingAssignmentComponent {

    @Input() bookingAssignment: BookingAssignmentDto;
    @Output() bookingAssignmentChange = new EventEmitter<BookingAssignmentDto>();
    public collapsed: boolean;

    public getJourneyDeparture(): JourneyStopDto {
        return _.first(this.bookingAssignment.journey.vehicleJourneyAtStops);
    }

    public getJourneyArrival(): JourneyStopDto {
        return _.last(this.bookingAssignment.journey.vehicleJourneyAtStops);
    }

    public toggle() {
        this.collapsed = !this.collapsed;
    }

    public getTotalPassengersCount(bookingAssignment: BookingAssignmentDto) {
        return bookingAssignment.booking.passengers.length;
    }

    public getTotalPassengersWithReducedMobilityCount(bookingAssignment: BookingAssignmentDto) {
        return bookingAssignment.booking.passengers.filter(passenger => passenger.reducedMobility).length;
    }

    public getTotalPassengersWithBikeCount(bookingAssignment: BookingAssignmentDto) {
        return bookingAssignment.booking.passengers.filter(passenger => passenger.bike).length;
    }
}
