import {Component, OnInit} from '@angular/core';
import {SessionService, UserDto} from 'auth-lib';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'shut-down',
    templateUrl: './shut-down.component.html',
    styleUrls: ['./shut-down.component.scss'],
    host: {class: 'cell shrink'}
})
export class ShutDownComponent implements OnInit {

    public user: UserDto;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.sessionService.connectedUser
            .pipe(
                filter(user => user !== undefined && user !== null),
            )
            .subscribe(user => this.user = user);
    }

    public isAdmin() {
        return this.user && this.user.roles.includes('ROLE_OPERATOR');
    }
}
