import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import * as moment_ from 'moment';

const moment = moment_;

@Directive({
    selector: '[validDateValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidDateDirective, multi: true}]
})
export class ValidDateDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: any } | null {
        return !moment(control.value).isValid() ? {validDate: {value: control.value}} : null;
    }
}
