import { NgModule } from '@angular/core';
import { AppModule } from './app/app.module';
import { TranslateModule } from '@ngx-translate/core';
import { ViewsRoutingModule } from './views-routing.module';
import { CommonModule } from '@angular/common';

const views: any[] = [];

@NgModule({
    declarations: [views],
    imports: [
        // 3rd party modules
        ViewsRoutingModule,
        TranslateModule,
        CommonModule,

        // app modules
        AppModule
    ],
    providers: [],
    exports: [views]
})
export class ViewsModule {
}
