import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { PostalAddressDto } from '../models/postal-address.model';

@Injectable()
export class PostalAddressService {

    constructor(private http: HttpClient) { }

    public normalize(q: string): Observable<PostalAddressDto[]> {
        const params = new HttpParams().append('q', q);
        return this.http
            .get<PostalAddressDto[]>('api/postalAddress/normalized', {params})
            .pipe(catchError(e => of(null)));
    }
}
