import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {TransportResourceAssignmentDto} from '../../../models/journeys-assignment-summary.model';
import {PersonDto} from 'base-lib';

@Component({
    selector: 'transport-resource-assignment-card',
    templateUrl: './transport-resource-assignment-card.component.html',
    styleUrls: ['./transport-resource-assignment-card.component.scss'],
    host: {class: 'cell shrink grid-y'}
})
export class TransportResourceAssignmentCardComponent {

    @ContentChild(TemplateRef) templateRef: TemplateRef<PersonDto>;
    @Input() transportResourceAssignment: TransportResourceAssignmentDto;
    @Input() showDelete: boolean = false;
    @Output() deleteAction: EventEmitter<TransportResourceAssignmentDto> = new EventEmitter();

    public deleteTransportResource() {
        this.deleteAction.emit(this.transportResourceAssignment);
    }
}
