import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {EnumService} from 'base-lib';
import {JourneyDirection} from '../../../models/journey.model';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BookingDirectionPickerComponent),
    multi: true
};

@Component({
    selector: 'booking-direction-picker',
    templateUrl: './booking-direction-picker.component.html',
    styleUrls: ['./booking-direction-picker.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class BookingDirectionPickerComponent implements OnInit, ControlValueAccessor {

    public journeyDirections: JourneyDirection[] = [];
    public selection: JourneyDirection;
    public disabled: boolean;

    constructor(private enumService: EnumService) {}

    ngOnInit(): void {
        this.enumService
            .fetchValues('journeyDirections')
            .subscribe(journeyDirections => this.journeyDirections = journeyDirections);
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(obj: any): void {
        this.selection = obj;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public isSelectedDirection(direction: JourneyDirection) {
        return direction === this.selection;
    }

    public onChangeJourneyDirection(direction: JourneyDirection) {
        this.writeValue(direction);
        this.onChange(direction);
    }
}
