import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DashboardMonitoringDto} from '../models/dashboard/dashboard.monitoring.model';
import {DashboardMonitoringCriteria} from '../models/dashboard/dashboard.monitoring.criteria';
import {DashboardSynthesisDto} from '../models/dashboard/dashboard.synthesis.model';

@Injectable()
export class DashboardMonitoringService {

    constructor(private http: HttpClient) {
    }

    public findDashboardMonitoring(criteria: DashboardMonitoringCriteria): Observable<DashboardMonitoringDto[]> {
        const params = criteria.toSearchParams();
        return this.http.get<DashboardMonitoringDto[]>('api/dashboard/monitoring', {params})
    }

    public findDashboardSynthesis(criteria: DashboardMonitoringCriteria): Observable<DashboardSynthesisDto[]> {
        const params = criteria.toSearchParams();
        return this.http.get<DashboardSynthesisDto[]>('api/dashboard/synthesis', {params})
    }

}
