import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BookingDto, BookingState} from '../models/booking.model';
import {Passenger} from '../models/passenger.model';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {ErrorReport} from 'base-lib';

@Injectable()
export class BookingService {

    constructor(private http: HttpClient) {}

    private prepare(bookings: BookingDto[]): BookingDto[] {
        const bookingList = _.cloneDeep(bookings);
        bookingList.forEach(booking => booking.passengers.forEach(passenger => passenger.passengerSelect = null));
        return bookingList;
    }

    private prepareBooking(booking: BookingDto): BookingDto {
        const bookingCopy = _.cloneDeep(booking);
        bookingCopy.passengers.forEach(passenger => passenger.passengerSelect = null);
        return bookingCopy;
    }

    public create(bookings: BookingDto[]) {
        return this.http.post<BookingDto>('api/booking', this.prepare(bookings));
    }

    public update(booking: BookingDto) {
        return this.http.post<BookingDto>(`api/booking/${booking.id}`, this.prepareBooking(booking));
    }

    public cancel(bookingId: string, reason: string = '') {
        const httpParams = new HttpParams().append('reason', reason);
        return this.http.delete<BookingDto>(`api/booking/${bookingId}`, {params: httpParams});
    }

    public find(bookingId: string) {
        return this.http.get<BookingDto>(`api/booking/${bookingId}`);
    }

    public checkPassengersConstraints(passengers: Passenger[]): Observable<ErrorReport[]> {
        const passengerList = _.cloneDeep(passengers);
        passengerList.forEach(passenger => passenger.passengerSelect = null);
        return this.http.post<ErrorReport[]>(`api/booking/passengerConstraint`, passengerList);
    }

    public nextDateOpenBooking() {
        return this.http.get<string>(`api/booking/nextOpenDate`);
    }

    public changeState(bookingId: string, state: BookingState) {
        const httpParams = new HttpParams()
            .append('state', state);
        return this.http.put<string[]>(`api/booking/${bookingId}`, httpParams);
    }

    public validate(bookingDtos: BookingDto[]): Observable<ErrorReport[]> {
        return this.http.post<ErrorReport[]>(`api/booking/validate`, this.prepare(bookingDtos));
    }
}
