import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExternalServiceDescriptor } from '../models/external-service.model';

@Injectable()
export class ExternalServicesService {

    private externalServicesConfig: any;

    constructor(private http: HttpClient) {}

    private fetchExternalServicesConfig(): Observable<ExternalServiceDescriptor[]> {
        if (this.externalServicesConfig) {
            return of(this.externalServicesConfig);
        }
        return this.http
            .get<ExternalServiceDescriptor[]>('api/config/external/services')
            .pipe(tap(config => this.externalServicesConfig = config));
    }

    public resolveUrl(id: string, url: string): Observable<string> {
        return this
            .fetchExternalServicesConfig()
            .pipe(
                map(config => config.find(service => service.id === id)),
                filter(service => service !== null),
                map(service => service.baseUrl),
                map(baseUrl => `${baseUrl}${url}`)
            );
    }
}
