import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { TransportResourceDto } from '../../../models/transport-resource.model';
import { TransportResourceService } from '../../../services/transport-resource.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TransportResourcePickerComponent),
    multi: true
};

@Component({
    selector: 'transport-resource-picker',
    templateUrl: './transport-resource-picker.component.html',
    styleUrls: ['./transport-resource-picker.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class TransportResourcePickerComponent implements OnInit, ControlValueAccessor {

    @Input() allowEmpty: boolean;
    resources: TransportResourceDto[];
    selection: TransportResourceDto;
    disabled: boolean;

    constructor(private transportResourceService: TransportResourceService,
                private translateService: TranslateService) {}

    ngOnInit(): void {
        this.transportResourceService
            .findAll()
            .pipe(
                map(resources => this.prependListWithEmptySelection(resources))
            )
            .subscribe(resources => this.resources = resources);
    }

    private prependListWithEmptySelection(resources: TransportResourceDto[]): TransportResourceDto[] {
        if (this.allowEmpty === true) {
            const transportResourceDto = new TransportResourceDto();
            transportResourceDto.id = null;
            transportResourceDto.name = this.translateService.instant('action.displayAll');
            return [transportResourceDto].concat(resources);
        }
        return resources;
    }

    onChange = (value: any) => {
    };

    onTouched = () => {
    };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(obj: any): void {
        this.selection = obj;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
