import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {PlannerService} from './planner.service';
import {JourneyService} from './journey.service';
import {PersonService} from './person.service';
import {BookingService} from './booking.service';
import {TransportResourceService} from './transport-resource.service';
import {BookingAssignmentService} from './booking-assignment.service';
import {PersonFavoriteService} from './person-favorite.service';
import {CompanyService} from './company.service';
import {ShuttleService} from './shuttle.service';
import {ShuttleMonthService} from './shuttle-month.service';
import {SettingsService} from './settings.service';
import {BookingPassengerService} from './booking-passenger.service';
import {UrbanAreaService} from './urban-area.service';
import {DashboardMonitoringService} from './dashboard.monitoring.service';
import {UserService} from '../../../../ident-iti/src/app/services/user.service';
import {JourneyBookingService} from './journey-booking.service';
import {LineService} from './line.service';

@NgModule({
    imports: [
        HttpClientModule,
        ToastrModule
    ],
    providers: [
        PlannerService,
        JourneyService,
        PersonService,
        UserService,
        BookingService,
        TransportResourceService,
        BookingAssignmentService,
        PersonFavoriteService,
        CompanyService,
        ShuttleService,
        ShuttleMonthService,
        SettingsService,
        BookingPassengerService,
        UrbanAreaService,
        DashboardMonitoringService,
        JourneyBookingService,
        LineService
    ]
})
export class ServicesModule {
}
