import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { ExternalServicesService } from '../../services/external-services.service';
import * as jQuery_ from 'jquery';

const jQuery = jQuery_;

@Directive({
    selector: 'a[external-service]'
})
export class ExternalServiceLinkDirective {

    @Input() href: string;
    private nativeElement: any;
    private resolvedUrl: string;

    constructor(private elt: ElementRef, private externalServicesService: ExternalServicesService) {
        this.nativeElement = jQuery(this.elt.nativeElement);
    }

    @Input('external-service')
    public set externalService(externalService: string) {
        this.externalServicesService
            .resolveUrl(externalService, this.href)
            .subscribe(resolvedUrl => this.resolvedUrl = resolvedUrl);
    }

    @HostBinding('href') get resolvedHref() {
        return this.resolvedUrl;
    }

    @HostBinding('target') get target() {
        return '_blank';
    }
}
