import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import * as moment_ from 'moment';

const moment = moment_;

@Directive({
    selector: '[beforeDateValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: BeforeDateDirective, multi: true}]
})
export class BeforeDateDirective implements Validator {
    @Input('beforeDateValidator') maxDate = moment();

    validate(control: AbstractControl): { [key: string]: any } | null {
        const error = moment(control.value).isAfter(this.maxDate);
        return error ? {beforeDate: {value: control.value}} : null;
    }
}
