import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JourneyStopDto } from '../../../models/journey.model';
import * as _ from 'lodash';
import { ShuttleActivation, ShuttleMonthDto, ShuttleMonthState } from '../../../models/shuttle.model';

@Component({
    selector: 'shuttle-card',
    templateUrl: './shuttle-card.component.html',
    styleUrls: ['./shuttle-card.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class ShuttleCardComponent {

    @Input() shuttle: ShuttleMonthDto;
    @Output() shuttleActivationChange: EventEmitter<ShuttleActivation> = new EventEmitter<ShuttleActivation>();

    public getJourneyDeparture(): JourneyStopDto {
        return _.first(this.shuttle.vehicleJourney.vehicleJourneyAtStops);
    }

    public getJourneyArrival(): JourneyStopDto {
        return _.last(this.shuttle.vehicleJourney.vehicleJourneyAtStops);
    }

    public changeShuttleState(shuttle: ShuttleMonthDto, state: ShuttleMonthState): void {
        this.shuttleActivationChange.emit(new ShuttleActivation(shuttle, state));
    }
}
