import {GeoLocation, Selectable} from 'base-lib';

export class UrbanAreaDto implements Selectable {
    id: string;
    name: string;
    cities: UrbanAreaCityDto[];

    public get _id(): string {
        return this.id;
    };

    public get label(): string {
        return this.name;
    }
}

export class UrbanAreaCityDto {
    name: string;
    insee: string;
    zipCode: string;
    location: GeoLocation;
}
