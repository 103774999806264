import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CountryDto} from '../models/country.model';

@Injectable()
export class CountryService {

    private readonly countries: CountryDto[] = [
        {
            name: 'France',
            dialCode: '+33',
            code: 'FR'
        },
        {
            name: 'Andorra',
            dialCode: '+376',
            code: 'AD'
        },
        {
            name: 'Austria',
            dialCode: '+43',
            code: 'AT'
        },
        {
            name: 'Belgium',
            dialCode: '+32',
            code: 'BE'
        },
        {
            name: 'Bulgaria',
            dialCode: '+359',
            code: 'BG'
        },
        {
            name: 'Croatia',
            dialCode: '+385',
            code: 'HR'
        },
        {
            name: 'Cyprus',
            dialCode: '+537',
            code: 'CY'
        },
        {
            name: 'Czech Republic',
            dialCode: '+420',
            code: 'CZ'
        },
        {
            name: 'Denmark',
            dialCode: '+45',
            code: 'DK'
        },
        {
            name: 'Estonia',
            dialCode: '+372',
            code: 'EE'
        },
        {
            name: 'Finland',
            dialCode: '+358',
            code: 'FI'
        },
        {
            name: 'Germany',
            dialCode: '+49',
            code: 'DE'
        },
        {
            name: 'Greece',
            dialCode: '+30',
            code: 'GR'
        },
        {
            name: 'Guadeloupe',
            dialCode: '+590',
            code: 'GP'
        },
        {
            name: 'Guyana',
            dialCode: '+595',
            code: 'GY'
        },
        {
            name: 'Haiti',
            dialCode: '+509',
            code: 'HT'
        },
        {
            name: 'Ireland',
            dialCode: '+353',
            code: 'IE'
        },
        {
            name: 'Italy',
            dialCode: '+39',
            code: 'IT'
        },
        {
            name: 'Lithuania',
            dialCode: '+370',
            code: 'LT'
        },
        {
            name: 'Luxembourg',
            dialCode: '+352',
            code: 'LU'
        },
        {
            name: 'Netherlands',
            dialCode: '+31',
            code: 'NL'
        },
        {
            name: 'Norway',
            dialCode: '+47',
            code: 'NO'
        },
        {
            name: 'Poland',
            dialCode: '+48',
            code: 'PL'
        },
        {
            name: 'Portugal',
            dialCode: '+351',
            code: 'PT'
        },
        {
            name: 'Romania',
            dialCode: '+40',
            code: 'RO'
        },
        {
            name: 'Slovakia',
            dialCode: '+421',
            code: 'SK'
        },
        {
            name: 'Slovenia',
            dialCode: '+386',
            code: 'SI'
        },
        {
            name: 'Spain',
            dialCode: '+34',
            code: 'ES'
        },
        {
            name: 'Sweden',
            dialCode: '+46',
            code: 'SE'
        },
        {
            name: 'Ukraine',
            dialCode: '+380',
            code: 'UA'
        },
        {
            name: 'United Kingdom',
            dialCode: '+44',
            code: 'GB'
        },
        {
            name: 'United States',
            dialCode: '+1',
            code: 'US'
        },
        {
            name: 'RÃ©union',
            dialCode: '+262',
            code: 'RE'
        },
        {
            name: 'Russia',
            dialCode: '+7',
            code: 'RU'
        }
    ];

    public fetchCountries(): Observable<CountryDto[]> {
        return of(this.countries);
    }
}
