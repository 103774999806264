import {Injectable} from '@angular/core';
import {SessionService} from '../services/session.service';
import {AbstractUserGuard} from './abstract-user.guard';
import {UserDto} from '../domain/user.model';

@Injectable()
export class IsAdminUserAuthenticatedGuard extends AbstractUserGuard {

    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    protected applyCondition(user: UserDto): boolean {
        return user !== null
            && user.roles
            && (user.roles.indexOf('ROLE_OPERATOR') >= 0
                || user.roles.indexOf('ROLE_TRANSPORTER') >= 0);
    }
}
