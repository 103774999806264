import {CompanyDto, Selectable} from 'base-lib';
import {LineDto} from "./line.model";

export class TransportResourceDto implements Selectable {

    id: string;
    name: string;
    companyId: string;
    company: CompanyDto;
    reducedMobilityCompatible: boolean;
    bikeCompatible: boolean;
    lines: LineDto[]

    public get _id(): string {
        return this.id;
    }

    public get label(): string {
        return this.name;
    }
}
