import {Component, forwardRef, Input} from '@angular/core';
import {MatchingJourneyDto} from '../../../models/journey.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {StopPoint} from '../../../models/stop-point.model';
import {AlertService} from 'base-lib';
import { StepType } from '../../../models/step-type';
import { Direction } from '../../../models/direction';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JourneyPickerComponent),
    multi: true
};

@Component({
    selector: 'journey-picker',
    templateUrl: './journey-picker.component.html',
    styleUrls: ['./journey-picker.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class JourneyPickerComponent implements ControlValueAccessor {

    @Input() journeys: MatchingJourneyDto[];
    @Input() direction: Direction;

    public journey: MatchingJourneyDto;
    public disabled: boolean;

    constructor(private alertService: AlertService) {
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.journey = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public isSelected(matchingJourney: MatchingJourneyDto) {
        return this.journey !== null
            && this.journey !== undefined
            && matchingJourney.journey.id === this.journey.journey.id;
    }

    public getStopPoint(matchingJourney: MatchingJourneyDto, stepType: StepType): StopPoint {
        return StopPoint.createFromStopPoint(stepType === 'DEPARTURE' ? matchingJourney.departureStop : matchingJourney.arrivalStop, stepType, matchingJourney.journey.shuttle)
    }

    public submit(result: MatchingJourneyDto): void {
        this.writeValue(result);
        this.onChange(result);
    }

    distanceInformation(matchingJourney: MatchingJourneyDto) {
        this.alertService
            .confirm('notification.info.bookingDistance.title', 'notification.info.bookingDistance.message')
            .subscribe();
    }
}
