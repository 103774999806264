import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Regex } from '../../../../models/regex';

@Directive({
    selector: '[phoneNumberValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: PhoneNumberDirective, multi: true}]
})
export class PhoneNumberDirective implements Validator {

    public validate(control: AbstractControl): { [key: string]: any } | null {
        const phoneNumber: string = control.value;
        if (phoneNumber) {
            const regExp = this.findAppropriateRegexp(phoneNumber);
            if (regExp && !regExp.test(phoneNumber)) {
                return {phoneNumberFormatIncorrect: {value: control.value}};
            }
        }
        return null;
    }

    private findAppropriateRegexp(phoneNumber: string): RegExp {
        for (const prefix in Regex.phoneNumbers) {
            if (phoneNumber.startsWith(prefix)) {
                return Regex.phoneNumbers[prefix];
            }
        }
        return null;
    }
}
