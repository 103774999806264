import { Component, forwardRef, Input } from '@angular/core';
import { GeoLocation } from '../../../models/postal-address.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MapComponent),
    multi: true
};

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [VALUE_ACCESSOR],
    host: {class: 'cell auto grid-y'}
})
export class MapComponent implements ControlValueAccessor {

    @Input() tileServerUrl: string;
    @Input() tileServerApiKey: string;
    @Input() initialLocation: GeoLocation;
    public disabled: boolean;

    public currentLocation: GeoLocation;

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.currentLocation = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
