import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment_ from 'moment';
import {Moment} from 'moment';
import {IDatePickerConfig, ISelectionEvent, SingleCalendarValue} from 'ng2-date-picker';

const moment = moment_;

@Component({
    selector: 'month-picker',
    templateUrl: './month-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthPickerComponent),
            multi: true
        }
    ]
})
export class MonthPickerComponent implements ControlValueAccessor {

    public config: IDatePickerConfig = {
        openOnFocus: false,
        firstDayOfWeek: 'mo',
        unSelectOnClick: false,
        closeOnSelectDelay: 0,
        min: this.minDate,
        max: this.maxDate,
        locale: 'fr',
        format: 'MMMM YYYY'
    };

    private onNgChange: (instant: string) => void;
    private onNgTouched: () => void;
    private _maxDate: string;
    private _minDate: string;
    public realDate: Moment;
    @Input() public disabled: boolean = false;
    @Input() public interactionButton: boolean = false;
    @Input() public format: string;

    @Input()
    public get maxDate(): string {
        return this._maxDate;
    }

    public set maxDate(maxDate: string) {
        this._maxDate = maxDate;
        this.config = {
            ...this.config,
            max: maxDate
        };
    }

    @Input()
    public get minDate(): string {
        return this._minDate;
    }

    public set minDate(minDate: string) {
        this._minDate = minDate;
        this.config = {
            ...this.config,
            min: minDate
        };
    }

    public onSelectionChange(event: ISelectionEvent): void {
        const date: Moment = this.computeDate(event.date);
        this.onChange(date);
    }

    private onChange(date: Moment): void {
        if (date === undefined) {
            this.onNgChange(undefined);
        } else if (this.format) {
            this.onNgChange(date.format(this.format));
        } else {
            this.onNgChange(date.toDate().toISOString());
        }
    }

    private computeDate(date: SingleCalendarValue): Moment {
        if (moment.isMoment(date)) {
            return date as Moment;
        } else {
            const m = moment(date, ['DD-MM-YYYY', 'DD/MM/YYYY']);
            if (m.isValid()) {
                return m;
            } else {
                return undefined;
            }
        }
    }

    public onOpen(): void {
        this.onNgTouched();
    }

    public writeValue(obj: string): void {
        if (obj !== null) {
            this.realDate = obj === undefined ? undefined : moment(obj);
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    public next() {
        if (this.realDate) {
            this.realDate = moment(this.realDate).add(1, 'months');
            this.onChange(this.realDate);
        }
    }

    public previous() {
        if (this.realDate) {
            this.realDate = moment(this.realDate).subtract(1, 'months');
            this.onChange(this.realDate);
        }
    }
}
