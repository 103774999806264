import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

export abstract class AbstractStepContentComponent {

    @ViewChild(NgForm) form?: NgForm;
    public active: boolean;

    public onEnabledStep(): void {}
    public onDisabledStep(): void {}
}
