import {Selectable} from './selectable.model';

export class CompanyDto implements Selectable {

    id: string;
    name: string;
    tin: string;
    email: string;

    public get _id(): string {
        return this.id;
    };

    public get label(): string {
        return this.name;
    }
}
