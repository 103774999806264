import {JourneyDto} from './journey.model';
import {Type} from 'class-transformer';

export class ShuttleDto {
    id: string;
    vehicleJourneyId: string;
    journeyPatternId: string;
    lineId: string;
    networkId: string;
    vehicleJourney: JourneyDto;
}

export class ShuttleMonthDto {
    id: string;
    vehicleJourneyId: string;
    startDate: Date;
    endDate: Date;
    @Type(() => JourneyDto)
    vehicleJourney: JourneyDto;
    state: ShuttleMonthState;
    stats: ShuttleMonthStats;
}

export class ShuttleMonthStats {
    travelerByDay: string;
    bookingDays: string;
    occupancyRate: string;
}

export class ShuttleActivation {
    shuttle: ShuttleMonthDto;
    state: ShuttleMonthState;
    constructor(shuttle: ShuttleMonthDto, state: ShuttleMonthState) {
        this.shuttle = shuttle;
        this.state = state;
    }
}

export type ShuttleMonthState = 'ACTIVE' | 'DISABLED' | 'NOT_CHOSEN';
