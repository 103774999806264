import {Directive, ElementRef} from '@angular/core';
import {RequestService} from '../../services/request.service';
import * as jQuery_ from 'jquery';

const jQuery = jQuery_;

@Directive({
    selector: 'button[processing]'
})
export class ButtonProcessingDirective {

    private spinnerBtn = '<i class="loadingBtnSpinner fa fa-spinner fa-spin"></i> ';
    private nativeElement: any;

    constructor(private elt: ElementRef, requestService: RequestService) {
        this.nativeElement = jQuery(this.elt.nativeElement);
        requestService.pendingRequestsCountSubject
            .subscribe(count => this.addProcessingClass(count));
    }

    private hasSpinner() {
        return this.nativeElement.children('.loadingBtnSpinner').length;
    }

    private removeSpinner() {
        if (this.hasSpinner()) {
            this.nativeElement.children().remove('.loadingBtnSpinner');
        }
    }

    private addSpinner() {
        if (!this.nativeElement.hasClass('disabled-btn') && !this.hasSpinner()) {
            this.removeSpinner();
            this.nativeElement.prepend(this.spinnerBtn);
        }
    }

    private addProcessingClass(count: number): void {
        if (count === 0) {
            this.removeSpinner();
            if (!this.nativeElement.hasClass('disabled-btn')) {
                this.nativeElement.removeAttr('disabled');

            }
        } else {
            this.nativeElement.attr('disabled', 'true');
            this.addSpinner();
        }
    }
}
