import {AfterViewInit, Component, ElementRef, forwardRef, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {PostalAddressService} from '../../../services/postal-address.service';
import {PostalAddressDto} from '../../../models/postal-address.model';

import * as jQuery_ from 'jquery';

const jQuery = jQuery_;

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PostalAddressSelectComponent),
    multi: true
};

@Component({
    selector: 'postal-address-select',
    templateUrl: './postal-address-select.component.html',
    styleUrls: ['./postal-address-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class PostalAddressSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    public id: string = Math.random().toString(36);
    public postalAddressQuerySubject: Subject<string> = new Subject<string>();
    public postalAddressQuery: string;
    public postalAddress: PostalAddressDto;
    public results: PostalAddressDto[];
    public disabled: boolean;

    @ViewChild('resultsDropdown') public resultsDropdownEl: ElementRef;
    private resultsDropdown: Foundation.Dropdown;

    constructor(private postalAddressService: PostalAddressService) {
    }

    ngOnInit(): void {
        this.postalAddressQuerySubject
            .pipe(
                tap(query => this.postalAddressQuery = query),
                tap(_ => this.onChange(null)),
                filter(addr => addr && addr.length > 3),
                debounceTime(500),
                switchMap(addr => this.postalAddressService.normalize(addr)),
                tap(addr => this.resultsDropdown.open()),
            )
            .subscribe(addresses => this.results = addresses, e => {});
    }

    ngAfterViewInit(): void {
        const jqEl = jQuery(this.resultsDropdownEl.nativeElement);
        this.resultsDropdown = new Foundation.Dropdown(jqEl);
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.postalAddress = obj;
        if (obj !== null && this.postalAddress && this.postalAddress.address) {
            this.postalAddressQuery = `${this.postalAddress.address} - ${this.postalAddress.zipCode} ${this.postalAddress.city}`;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onAddressChange(address: string): void {
        this.postalAddressQuerySubject.next(address);
    }

    public submit(result: PostalAddressDto): void {
        this.resultsDropdown.close();
        this.writeValue(result);
        this.onChange(result);
    }

    public isPostalAddress(result: PostalAddressDto) {
        return result.type === 'ADDRESS';
    }

    public isStopPoint(result: PostalAddressDto) {
        return result.type === 'STOP_POINT';
    }
}
