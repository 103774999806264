import { HttpParams } from '@angular/common/http';
import * as moment_ from 'moment';

const moment = moment_;

export abstract class SearchCriteria {

    private transformValue(value: any): any {
        if (value instanceof Date) {
            if (value['local'] === true) {
                return moment(value).format('YYYY-MM-DD');
            }
            return value.toISOString();
        } else if (value.getUri) {
            return value.getUri();
        }
        return value;
    }

    private getProperties(): string[] {
        return Object
            .getOwnPropertyNames(this)
            .filter(key => this[key] !== null && this[key] !== undefined);
    }

    public toQueryParams(): any {
        return this.getProperties()
            .reduce((p, key) => {
                p[key] = this.transformValue(this[key]);
                return p;
            }, {});
    }

    public toSearchParams(): HttpParams {
        return this.getProperties()
            .reduce((p, key) => p.set(key, this.transformValue(this[key])), new HttpParams());
    }
}
