import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BuildInfo} from '../models/build-info.model';

@Injectable()
export class ConfigService {

    constructor(private http: HttpClient) { }

    public fetchBuildInfo(): Observable<BuildInfo> {
        return this.http.get<BuildInfo>(`api/config/build`);
    }
}
