import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    JourneyAssignmentSummaryDto,
    TransportResourceAssignmentDto
} from '../../../models/journeys-assignment-summary.model';
import {JourneyStopDto} from '../../../models/journey.model';
import * as _ from 'lodash';
import {TransportResourceService} from '../../../services/transport-resource.service';
import {TransportResourceDto} from '../../../models/transport-resource.model';
import {BookingAssignmentService} from '../../../services/booking-assignment.service';
import {BookingDto} from '../../../models/booking.model';

@Component({
    selector: 'journey-optimizer',
    templateUrl: './journey-optimizer.component.html',
    styleUrls: ['./journey-optimizer.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class JourneyOptimizerComponent implements OnInit {

    @Input() summary: JourneyAssignmentSummaryDto;
    @Output() bookingChange = new EventEmitter<BookingDto>();
    public transportResources: TransportResourceDto[];
    public selectedTransportResource: TransportResourceDto;
    public collapsed: boolean = true;

    constructor(private transportResourceService: TransportResourceService,
                private bookingAssignmentService: BookingAssignmentService) {
    }

    ngOnInit(): void {
        this.transportResourceService
            .findAll()
            .subscribe(transportResources => this.transportResources = transportResources);
    }

    public getResourceNames(): string {
        return this.summary.resourceAssignments
            .map(assignment => assignment.resource)
            .filter(resource => resource !== undefined)
            .map(resource => resource.name)
            .join(', ');
    }

    public getJourneyDeparture(): JourneyStopDto {
        return _.first(this.summary.journey.vehicleJourneyAtStops);
    }

    public getJourneyArrival(): JourneyStopDto {
        return _.last(this.summary.journey.vehicleJourneyAtStops);
    }

    public addResource(resource: TransportResourceDto): void {
        this.summary.resourceAssignments.push(new TransportResourceAssignmentDto(resource, []));
    }

    public assignBookingToResource(event: any, resource: TransportResourceDto) {
        if (event.previousContainer !== event.container) {
            const booking = event.previousContainer.data[event.previousIndex];
            this.bookingAssignmentService
                .assignBookingToResource(booking.id, resource.id)
                .subscribe(value => this.moveBookingToResource(event.previousContainer.data, event.previousIndex, booking, resource.id));
        }
    }

    private moveBookingToResource(from: BookingDto[], fromIndex: number, booking: BookingDto, resourceId: string): void {
        from.splice(fromIndex, 1);
        this.summary.resourceAssignments
            .find(assignment => assignment.resource.id === resourceId)
            .bookings
            .push(booking);
    }

    public toggle() {
        this.collapsed = !this.collapsed;
    }

    public isCanceled(booking: BookingDto): boolean {
        return booking.state === 'CANCELED';
    }

    public isValid(bookingsToAssign: BookingDto[]) {
        return bookingsToAssign
            .filter(booking => booking.state === 'WAITING_APPROVAL' || booking.state === 'VALIDATED')
            .length === 0;
    }

    public removeTransportResource(transportResource: TransportResourceAssignmentDto) {
        this.summary.resourceAssignments = this.summary
            .resourceAssignments
            .filter(resource => resource.resource._id !== transportResource.resource._id);
    }

    public countShuttle(summary: JourneyAssignmentSummaryDto): number {
        const shuttleAssigned = summary.resourceAssignments
            .flatMap(assignment => assignment.bookings)
            .filter(booking => booking.shuttle).length;
        const shuttleToAssign = summary.bookingsToAssign
            .filter(booking => booking.shuttle).length;
        return shuttleAssigned + shuttleToAssign;
    }
}
