import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {Component, forwardRef, OnInit} from '@angular/core';
import {TransportResourceDto} from '../../../models/transport-resource.model';
import {CompanyDto} from 'base-lib';
import {CompanyService} from '../../../services/company.service';
import {LineService} from '../../../services/line.service';
import {LineDto} from '../../../models/line.model';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TransportResourceEditorComponent),
    multi: true
};

@Component({
    selector: 'transport-resource-editor',
    templateUrl: './transport-resource-editor.component.html',
    styleUrls: ['./transport-resource-editor.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class TransportResourceEditorComponent implements ControlValueAccessor, OnInit {

    public transportResource: TransportResourceDto;
    public company: CompanyDto;
    public disabled: boolean;
    public lines: LineDto[];

    constructor(private companyService: CompanyService,
                private lineService: LineService) {
    }

    ngOnInit(): void {
        this.lineService
            .findAllLines()
            .subscribe(lines => {
                this.lines = lines
                if (this.transportResource.lines) {
                    this.transportResource.lines = this.transportResource.lines
                        .map(line => this.lines.find(l => l.id === line.id));
                }
            });
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.transportResource = obj;
        if (obj && obj.companyId) {
            this.companyService.findCompanyById(obj.companyId)
                .subscribe(company => this.company = company);
        }
        if (obj && this.lines) {
            this.transportResource.lines = this.transportResource.lines
                .map(line => this.lines.find(l => l.id === line.id));
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
