import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Selectable } from '../../../models/selectable.model';

const CB_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboBoxComponent),
    multi: true
};

@Component({
    selector: 'combo-box',
    templateUrl: './combo-box.component.html',
    styleUrls: ['./combo-box.component.scss'],
    providers: [CB_VALUE_ACCESSOR]
})
export class ComboBoxComponent implements ControlValueAccessor {

    @Input() items: Selectable[];
    @Input() placeholder: string;
    @Input() emptyOption: string;
    @Input() disabled: boolean = false;

    selection: Selectable;

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.selection = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public compareFn = (a: Selectable, b: Selectable) => {
        return a === b || (a && b && a._id === b._id);
    }
}
