import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UrbanAreaDto} from '../../../models/urban-area.model';
import {UrbanAreaService} from '../../../services/urban-area.service';
import {Direction} from '../../../models/direction';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UrbanAreaSelectComponent),
    multi: true
};

@Component({
    selector: 'urban-area-select',
    templateUrl: './urban-area-select.component.html',
    styleUrls: ['./urban-area-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class UrbanAreaSelectComponent implements OnInit, ControlValueAccessor {

    public urbanAreas: UrbanAreaDto[];
    public selection: UrbanAreaDto;
    public disabled: boolean;
    @Input() direction: Direction = 'FROM';

    constructor(private urbanAreaService: UrbanAreaService) {
    }

    ngOnInit(): void {
        this.urbanAreaService
            .findAll()
            .subscribe(urbanAreas => this.urbanAreas = urbanAreas);
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(obj: any): void {
        this.selection = obj;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
