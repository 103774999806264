import { Injectable } from '@angular/core';
import { SessionService } from '../services/session.service';
import { AbstractUserGuard } from './abstract-user.guard';
import { UserDto } from '../domain/user.model';

@Injectable()
export class IsOperatorUserAuthenticatedGuard extends AbstractUserGuard {

    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    protected applyCondition(user: UserDto): boolean {
        return user !== null && user.roles && user.roles.indexOf('ROLE_OPERATOR') >= 0;
    }
}
