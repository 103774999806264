import { Component, Input } from '@angular/core';
import { BookingState } from '../../../models/booking.model';

@Component({
  selector: 'booking-state',
  templateUrl: './booking-state.component.html',
  styleUrls: ['./booking-state.component.scss']
})
export class BookingStateComponent {

    @Input() state: BookingState;

}
