import { Component, EventEmitter, Output } from '@angular/core';
import { StepComponent } from './step/step.component';
import { ActivatedRoute } from '@angular/router';
import { delay } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
    selector: 'stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class StepperComponent {

    private stepKeyRegex: RegExp = new RegExp('^([a-zA-Z]+).*$');
    steps: StepComponent[] = [];
    activeStep: StepComponent;
    activeStepIndex: number;
    @Output() stepChange: EventEmitter<StepComponent> = new EventEmitter();
    @Output() submit: EventEmitter<any> = new EventEmitter();
    validStep: boolean;

    constructor(private route: ActivatedRoute, private location: Location) {}

    public register(step: StepComponent): void {
        this.steps.push(step);
    }

    private enable(step: StepComponent) {
        this.steps.filter(s => s !== step).forEach(aStep => aStep.disable());
        step.enable();
        this.activeStep = step;
        this.activeStepIndex = this.steps.indexOf(step);
        this.validStep = false;
        if (this.activeStep && this.activeStep.content && this.activeStep.content.form) {
            this.validStep = this.activeStep.content.form.valid;
            this.activeStep.content.form.valueChanges
                .pipe(delay(0))
                .subscribe(e => this.validStep = this.activeStep.content.form.valid);
        }
        this.scrollToTop();
        this.stepChange.emit(step);
    }

    private scrollToTop() {
        const stepperBlockDiv = document.getElementById('stepperBlock');
        stepperBlockDiv.scrollTop = 0;
    }

    public enableByKey(key: string): void {
        const result = this.stepKeyRegex.exec(key);
        if (result !== null && result.length > 1) {
            const foundStep = this.steps.find(step => step.key === result[1]);
            if (foundStep) {
                this.enable(foundStep);
            } else {
                this.enable(this.steps[0]);
            }
        }
    }

    public isFirst(): boolean {
        return this.steps.length > 0 && this.activeStep === this.steps[0];
    }

    public isLast(): boolean {
        return this.steps.length > 0 && this.activeStep === this.steps[this.steps.length - 1];
    }

    public previous(): void {
        this.enable(this.steps[this.activeStepIndex - 1]);
    }

    public cancel(): void {
        if (this.isFirst() && this.isLast()) {
            this.location.back();
        }
    }

    public next(): void {
        this.enable(this.steps[this.activeStepIndex + 1]);
    }
}
