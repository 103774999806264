import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileRef} from '../models/file-ref.model';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class FileService {

    private readonly baseUrl: string = `api/file`;

    constructor(private http: HttpClient) {}

    public uploadFile(file: FileRef): Observable<FileRef> {
        if (file && file.file) {
            return Observable.create(subscriber => {
                this
                    .sendFile(file.file)
                    .pipe(
                        tap(descriptor => file.id = descriptor.id),
                        map(descriptor => file)
                    )
                    .subscribe(aFile => {
                        subscriber.next(aFile);
                        subscriber.complete();
                    });
            });
        } else {
            return of(file);
        }
    }

    private sendFile(file: File): Observable<{id: string}> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<{id: string}>(this.baseUrl, formData);
    }

    public getDownloadUrl(id: string): string {
        return `${this.baseUrl}/${id}`;
    }

    /**
     * Small hack to download a file from the navigator
     * It creates a fake <a> and simulate a click on it
     */
    public downloadFile(data: Blob, filename: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0);
    }
}
