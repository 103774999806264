import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {DpDatePickerModule} from 'ng2-date-picker';
import {WebcamModule} from 'ngx-webcam';
import {RecaptchaModule} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {AppHeaderComponent} from './header/app-header.component';
import {RouterModule} from '@angular/router';
import $ from 'jquery';
import {JourneyPickerComponent} from './journey/journey-picker/journey-picker.component';
import {BaseLibModule} from 'base-lib';
import {TravelerPickerComponent} from './journey/traveler-picker/traveler-picker.component';
import {FavoriteSelectComponent} from './journey/favorite-select/favorite-select.component';
import {JourneyOptimizerComponent} from './journey/journey-optimizer/journey-optimizer.component';
import {TransportResourcePickerComponent} from './transport-resource/transport-resource-picker/transport-resource-picker.component';
import {BookingCardComponent} from './booking/booking-card/booking-card.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TransportResourceAssignmentCardComponent} from './transport-resource/transport-resource-assignment-card/transport-resource-assignment-card.component';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {Dropdown} from 'foundation-sites/js/foundation.dropdown';
import {Box} from 'foundation-sites/js/foundation.util.box';
import {TransportResourceEditorComponent} from './transport-resource/transport-resource-editor/transport-resource-editor.component';
import {TransportResourceListComponent} from './transport-resource/transport-resource-list/transport-resource-list.component';
import {BookingDescriptionComponent} from './booking/booking-description/booking-description.component';
import {StopPointViewComponent} from './stop-point/stop-point-view/stop-point-view.component';
import {BookingPassengersComponent} from './booking/booking-passengers/booking-passengers.component';
import {CompanySelectComponent} from './common/company-select/company-select.component';
import {ShuttleCardComponent} from './shuttle/shuttle-card/shuttle-card.component';
import {BookingAssignmentComponent} from './booking/booking-assignment/booking-assignment.component';
import {BookingStateComponent} from './booking/booking-state/booking-state.component';
import {BookingAssignmentPassengersComponent} from './booking/booking-assignment/booking-assignment-passengers/booking-assignment-passengers.component';
import {UrbanAreaSelectComponent} from './urban-area/urban-area-select/urban-area-select.component';
import {BookingDirectionPickerComponent} from './booking/booking-direction-picker/booking-direction-picker.component';
import {UrbanAreaStopAreaSelectComponent} from './urban-area/urban-area-stop-area-select/urban-area-stop-area-select.component';
import {DashboardMonitoringTableComponent} from './dashboard/dashboard-monitoring-table/dashboard-monitoring-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableExporterModule} from 'mat-table-exporter';
import {MatButtonModule} from '@angular/material/button';
import {DashboardSynthesisTableComponent} from './dashboard/dashboard-synthesis-table/dashboard-synthesis-table.component';
import {AuthLibModule} from '../../../../auth-lib/src/lib/auth-lib.module';
import {MatInputModule} from '@angular/material/input';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {DialogEditJourneyBookingComponent} from './dashboard/dialog-edit-journey-booking/dialog-edit-journey-booking.component';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {getFrenchPaginatorIntl} from './french-paginator-intl';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ShutDownComponent} from './shut-down/shut-down.component';

Foundation.addToJquery($);
Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(Box, 'Box');

const components: any[] = [
    AppHeaderComponent,
    JourneyPickerComponent,
    JourneyOptimizerComponent,
    TravelerPickerComponent,
    FavoriteSelectComponent,
    TransportResourcePickerComponent,
    BookingCardComponent,
    TransportResourceAssignmentCardComponent,
    FavoriteSelectComponent,
    BookingDescriptionComponent,
    TransportResourceEditorComponent,
    TransportResourceListComponent,
    StopPointViewComponent,
    BookingPassengersComponent,
    CompanySelectComponent,
    ShuttleCardComponent,
    BookingAssignmentComponent,
    BookingStateComponent,
    BookingAssignmentPassengersComponent,
    UrbanAreaSelectComponent,
    BookingDirectionPickerComponent,
    UrbanAreaStopAreaSelectComponent,
    DashboardSynthesisTableComponent,
    DashboardMonitoringTableComponent,
    ShutDownComponent
];

const pipes: any[] = [];

const directives: any[] = [];

@NgModule({
    imports: [
        // 3rd party modules
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        ToastrModule,
        DpDatePickerModule,
        WebcamModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        DragDropModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableExporterModule,
        // libs
        BaseLibModule.forChild(),
        AuthLibModule.forChild(),
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        MatCardModule,
        MatRadioModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
        {provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl() }
    ],
    declarations: [components, pipes, directives, DialogEditJourneyBookingComponent],
    entryComponents: [DialogEditJourneyBookingComponent],
    exports: [components, pipes, directives, BaseLibModule]
})
export class ComponentsModule {
}
