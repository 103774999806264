import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]
})
export class RadioComponent implements ControlValueAccessor {

    private onNgChange: (value: boolean) => void;
    private onNgTouched: () => void;

    public disabled: boolean;
    public checked: boolean;

    @Input()
    public label: string;

    @Input()
    public value: any;

    @Input()
    public name: string;

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: boolean): void {
        this.checked = obj;
    }

    public onCheckedChange(checked: boolean): void {
        this.onNgChange(checked);
        this.checked = checked;
    }
}
