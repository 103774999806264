import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { GeoLocation } from '../../models/postal-address.model';

declare const L: any;
type MapGeoLocation = [number, number];

@Directive({
    selector: '[map]'
})
export class MapDirective implements AfterViewInit {

    @Input() tileServerUrl: string;
    @Input() tileServerApiKey: string;
    @Input() initialLocation: GeoLocation;
    @Input() disabled: boolean;
    @Output() currentLocationChange: EventEmitter<GeoLocation> = new EventEmitter<GeoLocation>();

    private _currentLocation: GeoLocation;
    private currentLocationMarker: any;
    private map: any;

    constructor(private el: ElementRef) {}

    ngAfterViewInit(): void {
        this.initMapIfNecessary();
    }

    private initMapIfNecessary() {
        if (!this.map) {
            this.map = L.map(this.el.nativeElement);
            if (!this.disabled) {
                this.registerHandlers();
            }
            const config = {maxZoom: 25, accessToken: this.tileServerApiKey};
            L.tileLayer(`${this.tileServerUrl}?apikey=${this.tileServerApiKey}`, config).addTo(this.map);
            this.map.setView(this.toMapLocation(this.initialLocation), 16, {animate: true});
        }
    }

    private registerHandlers(): void {
        this.map.on('click', (e) => {
            const currentLocation = new GeoLocation(e.latlng.lat, e.latlng.lng);
            this.setMarker(currentLocation);
            this.notifyChange(currentLocation);
        });
    }

    private panTo(location: GeoLocation): void {
        this.initMapIfNecessary();
        if (location) {
            this.map.panTo(this.toMapLocation(location), {animate: true});
            this.setMarker(location);
        }
    }

    private setMarker(location: GeoLocation): void {
        if (this.currentLocationMarker) {
            this.currentLocationMarker.remove();
        }
        this.currentLocationMarker = L.marker(this.toMapLocation(location), {draggable: true, autoPan: true})
            .addTo(this.map);
        this.currentLocationMarker
            .on('moveend', (e) => this.notifyChange(new GeoLocation(e.target._latlng.lat, e.target._latlng.lng)));
    }

    private notifyChange(location: GeoLocation): void {
        this._currentLocation = location;
        this.currentLocationChange.emit(this._currentLocation);
    }

    private toMapLocation(location: GeoLocation): MapGeoLocation {
        return [location.latitude, location.longitude];
    }

    @Input() set currentLocation(currentLocation: GeoLocation) {
        if (currentLocation && currentLocation.longitude !== 0 && currentLocation.latitude !== 0) {
            this._currentLocation = currentLocation;
            this.panTo(currentLocation);
        }
    }
}
