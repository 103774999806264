import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShuttleMonthDto, ShuttleMonthState } from '../models/shuttle.model';
import * as moment from 'moment';
import { ShuttleSearchCriteria } from '../models/shuttle-search.model';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Service } from './service';

@Injectable()
export class ShuttleMonthService extends Service {

    constructor(private http: HttpClient) {
        super();
    }

    public find(criteria: ShuttleSearchCriteria) {
        const date = moment(criteria.date).format('YYYY-MM');
        return this.http.get<ShuttleMonthDto[]>(`api/shuttle/activation/${date}`)
            .pipe(
                map(dtos => plainToClass(ShuttleMonthDto, dtos), this.handleError)
            );
    }

    public create(month: Date, vehicleJourneyId: string, state: ShuttleMonthState) {
        const date = moment(month).format('YYYY-MM');
        return this.http.post<ShuttleMonthDto>(`api/shuttle/activation/${date}/${vehicleJourneyId}/state/${state}`, {})
    }
}
