import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { RequestService } from './request.service';
import { Observable, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class PendingRequestsHttpInterceptor implements HttpInterceptor {

    private readonly ignoredUrls: string[] = [];

    constructor(private requestService: RequestService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestService.addPendingRequest();
        return next
            .handle(request)
            .pipe(
                tap(event => this.handleResponse(event), error => this.handleError(error)),
                finalize(() => this.requestService.popPendingRequest())
            )
    }
    private handleResponse(event: HttpEvent<any>): void {
        // nothing done for the moment
    }

    private handleError(err: any) {
        // nothing done for the moment
        return throwError(err);
    }
}
