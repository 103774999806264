export abstract class EntityDto {
    id: string;
    auditItem: AuditItem;
}

export class AuditItem {
    insert: AuditItemDescriptor;
    edit: AuditItemDescriptor;
    delete: AuditItemDescriptor;
}

export class AuditItemDescriptor {
    author: string;
    date: Date;
}
