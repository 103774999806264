import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { Passenger } from '../../../models/passenger.model';
import { PersonService } from '../../../services/person.service';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { PersonDto } from 'base-lib';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TravelerPickerComponent),
    multi: true
};

@Component({
    selector: 'traveler-picker',
    templateUrl: './traveler-picker.component.html',
    styleUrls: ['./traveler-picker.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class TravelerPickerComponent implements OnInit, ControlValueAccessor {

    @Input() person: PersonDto;
    public authorizedPassengers: Passenger[];
    public passengers: Passenger[];
    public disabled: boolean;

    constructor(private personService: PersonService) { }

    ngOnInit() {
        this.personService
            .findPassengers(this.person.id)
            .pipe(
                map(persons => persons.map(person => Passenger.fromPerson(person)))
            )
            .subscribe(passengers => {
                this.authorizedPassengers = passengers;
                this.addSimplePassenger();
            });
    }

    onChange = (value: any) => {
    };
    onTouched = () => {
    };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.passengers = obj;
    }

    public addPassenger(): void {
        if (!this.authorizedPassengers) {
            this.passengers = [];
        }
        const passenger = _.cloneDeep(this.authorizedPassengers[0]);
        passenger.passengerSelect = this.authorizedPassengers[0];
        this.passengers.push(passenger);
        this.onChange(this.passengers);
    }

    public removePassenger(i: number): void {
        this.passengers.splice(i, 1);
        this.onChange(this.passengers);
    }

    private addSimplePassenger() {
        this.authorizedPassengers.push(new Passenger('', '', moment().toDate(), false, null));
    }

    changePassenger(passengerOrigin: Passenger, passengerSelect: Passenger) {
        const index = this.passengers.indexOf(passengerOrigin);
        this.passengers[index] = _.cloneDeep(passengerSelect);
        this.passengers[index].passengerSelect = passengerSelect;
        this.onChange(this.passengers);
    }

}
