import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BookingState} from '../models/booking.model';

@Injectable()
export class BookingPassengerService {

    constructor(private http: HttpClient) {
    }

    public changeState(passengerId: string, state: BookingState) {
        return this.http.put<string[]>(`api/passenger/${passengerId}/state/${state}`, {})
    }

    public changeStateAll(state: BookingState, date: string) {
        return this.http.put<string[]>(`api/passenger/batch/${date}/state/${state}`, {})
    }
}
