import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PostalAddressDto} from 'base-lib';
import {UrbanAreaDto} from '../../../models/urban-area.model';
import {UrbanAreaService} from '../../../services/urban-area.service';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UrbanAreaStopAreaSelectComponent),
    multi: true
};

@Component({
    selector: 'urban-area-stop-area-select',
    templateUrl: './urban-area-stop-area-select.component.html',
    styleUrls: ['./urban-area-stop-area-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class UrbanAreaStopAreaSelectComponent implements ControlValueAccessor {

    public postalAddress: PostalAddressDto[] = [];
    @Input() disabled: boolean = false;
    private _urbanArea: UrbanAreaDto;

    public selected: string;
    public loading: boolean = false;

    constructor(private urbanAreaService: UrbanAreaService) {
    }

    get urbanArea(): UrbanAreaDto {
        return this._urbanArea;
    }

    @Input()
    set urbanArea(urbanAreaDto: UrbanAreaDto) {
        this._urbanArea = urbanAreaDto;
        this.loadPostalAddress();
    }

    private loadPostalAddress() {
        if (this.urbanArea) {
            this.loading = true;
            this.urbanAreaService.findUrbanAreaPostalAddresses(this.urbanArea.id)
                .subscribe(postalAddress => {
                    this.postalAddress = postalAddress;
                    this.loading = false;
                });
        }
    }

    onChange = (_: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selected = obj;
    }

    public changeValue(): void {
        this.writeValue(this.selected);
        this.onChange(this.selected);
    }
}
