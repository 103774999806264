import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DashboardMonitoringDto} from '../../../models/dashboard/dashboard.monitoring.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ExportType, MatTableExporterDirective} from 'mat-table-exporter';
import {JourneyStopDto} from '../../../models/journey.model';
import * as moment from 'moment';
import {SessionService} from 'auth-lib';
import {filter, map} from 'rxjs/operators';
import {Role} from 'auth-lib/lib/domain/role.model';
import {MatDialog} from '@angular/material/dialog';
import {DialogEditJourneyBookingComponent} from '../dialog-edit-journey-booking/dialog-edit-journey-booking.component';

@Component({
    selector: 'dashboard-monitoring-table',
    templateUrl: './dashboard-monitoring-table.component.html',
    styleUrls: ['./dashboard-monitoring-table.component.scss'],
    host: {style: 'width:100%'},
})
export class DashboardMonitoringTableComponent implements OnInit, OnChanges {

    @Input() dashboardMonitorings: DashboardMonitoringDto[];
    @Input() isLoading: boolean;
    public displayedColumns: string[];
    dataSource: MatTableDataSource<DashboardMonitoringDto>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective) exporter: MatTableExporterDirective;
    public connectedUserRoles: Role[];

    constructor(private sessionService: SessionService,
                public dialog: MatDialog) {
        this.sessionService.connectedUser
            .pipe(
                filter(u => u !== undefined && u !== null),
                map(u => u.roles.filter(role => ['ROLE_OPERATOR', 'ROLE_SUBSCRIBER', 'ROLE_CHILD_SUBSCRIBER', 'ROLE_TRANSPORTER'].includes(role)))
            )
            .subscribe(connectedUserRoles => this.connectedUserRoles = connectedUserRoles);
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource<DashboardMonitoringDto>(this.dashboardMonitorings)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.displayedColumns = ['passengerCount', 'passengers', 'date', 'resource', 'company', 'line', 'course',
            'stopPointDepartureName', 'stopPointDepartureTime',
            'stopPointArrivalName', 'stopPointArrivalTime',
            'stopPointsWithBooking', 'distance',
            'transporterNotes', 'operatorNotes'
        ];
    }

    ngOnChanges(changes: SimpleChanges) {
        this.dataSource = new MatTableDataSource<DashboardMonitoringDto>(this.dashboardMonitorings)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'line': return item.journey.lineName;
                case 'course': return item.journey.name;
                default: return item[property];
            }
        };
        this.dataSource.sort = this.sort;
    }

    getStopPointDeparture(element: DashboardMonitoringDto): JourneyStopDto {
        return element.journey.vehicleJourneyAtStops.length > 0 ? element.journey.vehicleJourneyAtStops[0] : new JourneyStopDto()
    }

    getDepartureTime(element: DashboardMonitoringDto): string {
        if (element.journey.vehicleJourneyAtStops.length > 0) {
            const departureTime = element.journey.vehicleJourneyAtStops[0].departureTime;
            return this.formatTime(departureTime);
        }
        return '';
    }

    getArrivalTime(element: DashboardMonitoringDto): string {
        if (element.journey.vehicleJourneyAtStops.length > 0) {
            const arrivalTime = element.journey.vehicleJourneyAtStops[element.journey.vehicleJourneyAtStops.length - 1].arrivalTime;
            return this.formatTime(arrivalTime);
        }
        return '';
    }

    private formatTime(time) {
        return moment(time, [moment.ISO_8601, 'HH:mm:ss']).format('HH:mm')
    }

    getStopPointArrival(element: DashboardMonitoringDto): JourneyStopDto {
        return element.journey.vehicleJourneyAtStops.length > 0 ? element.journey.vehicleJourneyAtStops[element.journey.vehicleJourneyAtStops.length - 1] : new JourneyStopDto();
    }

    getStopPointsUpsAndDown(element: DashboardMonitoringDto) {
        return element.journey.vehicleJourneyAtStops
            .filter(stop => element.stopPointUpsOrDowns.includes(Number(stop.stopPointId)))
            .map(stop => `${stop.stopPointId} - ${this.formatTime(stop.arrivalTime)}`)
            .join('\n');
    }

    export() {
        this.exporter.exportTable(ExportType.CSV, {fileName: 'export-dashboard'});
    }

    openDialog(element: DashboardMonitoringDto) {
        const dialogRef = this.dialog.open(DialogEditJourneyBookingComponent, {
            width: '600px',
            data: {
                journeyBooking: element.journeyBooking,
                connectedUserRoles: this.connectedUserRoles
            }
        });

        dialogRef.afterClosed().subscribe(journeyBooking => {
            if (journeyBooking != null) {
                element.journeyBooking = journeyBooking;
            }
        });
    }

    getTotalPassengers(element: DashboardMonitoringDto) {
        return element.bookings
            .flatMap(booking => booking.passengers)
            .length
    }

    getPassengersSummary(element: DashboardMonitoringDto) {
        return element.bookings
            .flatMap(booking => booking.passengers)
            .map(passenger => `${passenger.firstName} ${passenger.lastName}`)
            .join('\n')
    }
}
