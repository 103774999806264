import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { UserDto } from '../domain/user.model';

export abstract class AbstractUserGuard implements CanLoad {

    protected constructor(protected sessionService: SessionService) { }

    protected abstract applyCondition(user: UserDto): boolean;

    protected afterUserResolving(result: boolean): void {
        // nothing to do here
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return Observable.create(subscriber => {
            this.sessionService
                .connectedUser
                .pipe(
                    filter(user => user !== undefined),
                    map(user => this.applyCondition(user)),
                    tap(result => this.afterUserResolving(result))
                )
                .subscribe(result => {
                    subscriber.next(result);
                    subscriber.complete();
                });
        });
    }
}
