import { Component, Input } from '@angular/core';
import { ErrorReport } from '../../../models/error-report.model';

@Component({
    selector: 'business-validation-errors',
    templateUrl: './business-validation-errors.component.html',
    styleUrls: ['./business-validation-errors.component.scss']
})
export class BusinessValidationErrorsComponent {

    @Input() errors: ErrorReport[];

}
