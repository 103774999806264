import { JourneyDto } from './journey.model';
import { BookingDto } from './booking.model';
import { TransportResourceDto } from './transport-resource.model';

export class JourneysAssignmentSummaryDto {
    date: Date;
    journeys: JourneyAssignmentSummaryDto[];

    constructor(date: Date, journeys: JourneyAssignmentSummaryDto[]) {
        this.date = date;
        this.journeys = journeys;
    }
}

export class JourneyAssignmentSummaryDto {
    journey: JourneyDto;
    resourceAssignments: TransportResourceAssignmentDto[];
    bookingsToAssign: BookingDto[];
    totalPassengersCount: number;
    totalPassengersWithBikeCount: number;
    totalPassengersWithReducedMobilityCount: number;

    constructor(journey: JourneyDto, resourceAssignments: TransportResourceAssignmentDto[], bookingsToAssign: BookingDto[]) {
        this.journey = journey;
        this.resourceAssignments = resourceAssignments;
        this.bookingsToAssign = bookingsToAssign;
    }
}

export class TransportResourceAssignmentDto {
    resource: TransportResourceDto;
    bookings: BookingDto[];

    constructor(resource: TransportResourceDto, bookings: BookingDto[]) {
        this.resource = resource;
        this.bookings = bookings;
    }
}
