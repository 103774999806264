import { ModuleWithProviders, NgModule } from '@angular/core';
import { SessionService } from './services/session.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { KeycloakService } from './services/keycloak.service';
import { CommonModule } from '@angular/common';
import { ShownForRolesDirective } from './components/shown-for-roles.directive';
import { HiddenForRolesDirective } from './components/hidden-for-roles.directive';

const components: any[] = [];

const directives: any[] = [
    ShownForRolesDirective,
    HiddenForRolesDirective
];

const services: any[] = [
    KeycloakService,
    SessionService,
    ConfigService
];

@NgModule({
    declarations: [components, directives],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule
    ],
    exports: [components, directives]
})
export class AuthLibModule {

    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthLibModule,
            providers: [services]
        };
    }

    public static forChild(): ModuleWithProviders {
        return {
            ngModule: AuthLibModule
        };
    }
}
