import {Component, Input} from '@angular/core';
import {Passenger} from '../../../../models/passenger.model';
import {BookingPassengerService} from '../../../../services/booking-passenger.service';
import {BookingState} from '../../../../models/booking.model';
import { NotificationService } from 'base-lib';

@Component({
    selector: 'booking-assignment-passengers',
    templateUrl: './booking-assignment-passengers.component.html',
    styleUrls: ['./booking-assignment-passengers.component.scss']
})
export class BookingAssignmentPassengersComponent {

    @Input() passengers: Passenger[];

    constructor(private bookingPassengerService: BookingPassengerService,
                private notificationService: NotificationService) {
    }

    public onChange(passenger: Passenger, bookingState: BookingState) {
        this.bookingPassengerService
            .changeState(passenger.id, bookingState)
            .subscribe(
                ignored => this.notificationService.success('notification.success.bookingPassengerChangeState').subscribe(),
                ignored => this.notificationService.error('notification.error.bookingPassengerChangeState').subscribe()
            );
    }
}
