import {Injectable} from '@angular/core';
import {SessionService} from '../services/session.service';
import {AbstractUserGuard} from './abstract-user.guard';
import {UserDto} from '../domain/user.model';

@Injectable()
export class IsTransporterUserAuthenticatedGuard extends AbstractUserGuard {

    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    protected applyCondition(user: UserDto): boolean {
        const b = user !== null && user.roles && user.roles.indexOf('ROLE_TRANSPORTER') >= 0;
        console.log(`isTransporter=${b}`)
        return b;
    }
}
