import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ObservableInput, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserDto } from '../domain/user.model';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { KeycloakService } from './keycloak.service';

@Injectable()
export class SessionService {

    public connectedUser: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(undefined);

    constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

    public fetchCurrentUser(): Observable<UserDto> {
        return this.keycloakService
            .init()
            .pipe(
                tap(token => console.log('keycloak token loaded', token !== null)),
                switchMap(token => {
                    if (token !== null) {
                        return this.http.get<UserDto>('api/auth/current');
                    }
                    return of(null);
                }),
                catchError(error => this.removeUser()),
                tap(user => this.connectedUser.next(user))
            );
    }

    private removeUser(): ObservableInput<UserDto> {
        this.connectedUser.next(null);
        return Observable.create(null);
    }

    public login(): Observable<string> {
        return this.keycloakService.login();
    }

    public logout(): Observable<any> {
        return this.http
            .post<any>('/sso/logout', {}, {withCredentials: true})
            .pipe(
                catchError(e => this.keycloakService.logout()),
                switchMap(e => this.keycloakService.logout())
            );
    }
}
