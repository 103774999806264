import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PostalAddressDto} from 'base-lib';

@Injectable()
export class PersonFavoriteService {

    constructor(private http: HttpClient) {
    }

    public addFavorite(personId: string, bookingId: string): Observable<any> {
        return this.http.post<any>(`api/person/${personId}/favorite/${bookingId}`, {})
    }

    public removeFavorite(personId: string, bookingId: string): Observable<any> {
        return this.http.delete<any>(`api/person/${personId}/favorite/${bookingId}`, {})
    }

    public findFavorites(personId: string, withChild: boolean = false): Observable<PostalAddressDto[]> {
        const httpParams = new HttpParams()
            .append('withChild', String(withChild));
        return this.http.get<PostalAddressDto[]>(`api/person/${personId}/favorite`, {params: httpParams})
    }
}
