import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeSlotSelectListComponent),
    multi: true
};

@Component({
    selector: 'time-slot-select-list',
    templateUrl: './time-slot-select-list.component.html',
    styleUrls: ['./time-slot-select-list.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class TimeSlotSelectListComponent implements OnInit, ControlValueAccessor {

    @Input() list: string[];
    @Input() disabled: boolean = false;

    public selected: string;

    constructor() {
    }

    ngOnInit() {
    }

    onChange = (_: any) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selected = obj;
    }

    public changeValue(): void {
        this.writeValue(this.selected);
        this.onChange(this.selected);
    }
}

