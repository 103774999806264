import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'i18nBoolean'
})
export class I18nBooleanPipe implements PipeTransform {

    public transform(value: boolean): any {
        return value === true ? 'yes' : 'no';
    }
}
