import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RequestService {

    public pendingRequestsCountSubject = new Subject<number>();
    private pendingRequestsCount: number = 0;

    public addPendingRequest() {
        this.pendingRequestsCountSubject.next(++this.pendingRequestsCount);
    }

    public popPendingRequest() {
        this.pendingRequestsCountSubject.next(--this.pendingRequestsCount);
    }
}
