import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app-root.component.html',
    styleUrls: ['./app-root.component.scss']
})
export class AppRootComponent {

    constructor(translateService: TranslateService) {
        translateService.setDefaultLang('fr');
        translateService.use('fr');
    }
}
