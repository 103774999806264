import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { StepperComponent } from '../stepper.component';
import { AbstractStepContentComponent } from './abstract-step-content-component';

@Component({
    selector: 'step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

    @Input() key: string;
    @Input() i18nTitle: string;
    @ContentChild(AbstractStepContentComponent) content: AbstractStepContentComponent;
    private _active: boolean = false;

    constructor(private stepperComponent: StepperComponent) {}

    ngOnInit() {
        this.stepperComponent.register(this);
    }

    public get active(): boolean {
        return this._active;
    }

    public enable(): void {
        this._active = true;
        this.content.onEnabledStep();
        this.content.active = true;
    }

    public disable(): void {
        this._active = false;
        this.content.onDisabledStep();
        this.content.active = false;
    }
}
