import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JourneyBookingDto} from '../../../models/journey-booking.model';
import {Role} from 'auth-lib/lib/domain/role.model';
import {JourneyBookingService} from '../../../services/journey-booking.service';

@Component({
    selector: 'dialog-edit-journey-booking',
    templateUrl: 'dialog-edit-journey-booking.component.html',
})
export class DialogEditJourneyBookingComponent {
    private journeyBooking: JourneyBookingDto;
    private connectedUserRoles: Role[];

    constructor(
        public dialogRef: MatDialogRef<DialogEditJourneyBookingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public journeyBookingService: JourneyBookingService) {
        this.journeyBooking = data.journeyBooking;
        this.connectedUserRoles = data.connectedUserRoles;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    updateJourneyBooking() {
        this.journeyBookingService.updateJourneyBooking(this.journeyBooking)
            .subscribe(jb => {
                this.dialogRef.close(jb);
            });
    }

    isOperator() {
        return this.connectedUserRoles.includes('ROLE_OPERATOR');
    }
    isTransporter() {
        return this.connectedUserRoles.includes('ROLE_TRANSPORTER');
    }
}
