import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyDto } from 'base-lib';
import { map } from 'rxjs/operators';
import { Service } from './service';
import { plainToClass } from 'class-transformer';

@Injectable()
export class CompanyService extends Service {

    constructor(private http: HttpClient) {
        super();
    }

    public findAllCompanies(): Observable<CompanyDto[]> {
        return this.http
            .get<CompanyDto[]>(`api/company`)
            .pipe(map(dtos => plainToClass(CompanyDto, dtos)));
    }

    public findCompanyById(id: string): Observable<CompanyDto> {
        return this.http
            .get<CompanyDto>(`api/company/${id}`)
            .pipe(map(dtos => plainToClass(CompanyDto, dtos)));
    }
}
