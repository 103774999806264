import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {
    ApiHttpInterceptor,
    AuthLibModule,
    IsAdminUserAuthenticatedGuard,
    IsOperatorUserAuthenticatedGuard,
    IsStdUserAuthenticatedGuard,
    IsTransporterUserAuthenticatedGuard,
    IsUserAuthenticatedGuard,
    OperatorRedirectionGuard,
    TransporterRedirectionGuard
} from 'auth-lib';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BaseLibModule, BusinessErrorHttpInterceptor, PendingRequestsHttpInterceptor} from 'base-lib';

const routes: Route[] = [
    {path: '', redirectTo: '/app/home', pathMatch: 'full'},
    {
        path: 'app',
        loadChildren: './app/app.module#AppModule',
        canLoad: [IsUserAuthenticatedGuard, OperatorRedirectionGuard, TransporterRedirectionGuard, IsStdUserAuthenticatedGuard]
    },
    {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canLoad: [IsUserAuthenticatedGuard, IsAdminUserAuthenticatedGuard]
    },
    {path: '**', redirectTo: '/app/home'}
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, {useHash: true}),
        AuthLibModule.forRoot(),
        BaseLibModule.forRoot(),
    ],
    providers: [
        IsUserAuthenticatedGuard,
        IsStdUserAuthenticatedGuard,
        IsOperatorUserAuthenticatedGuard,
        OperatorRedirectionGuard,
        TransporterRedirectionGuard,
        IsTransporterUserAuthenticatedGuard,
        IsAdminUserAuthenticatedGuard,
        {provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: PendingRequestsHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BusinessErrorHttpInterceptor, multi: true},
    ],
    exports: [RouterModule]
})
export class ViewsRoutingModule {
}
