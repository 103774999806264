import {Selectable} from './selectable.model';

export class PostalAddressDto implements Selectable {

    principal: boolean;
    address: string;
    zipCode: string;
    city: string;
    additional: string;
    type: PostalAddressType;
    country: string;
    geoLocation: GeoLocation;

    public get _id(): string {
        return this.address;
    };

    public get label(): string {
        return this.address;
    }
}

export type PostalAddressType = 'ADDRESS' | 'STOP_POINT';

export class GeoLocation {
    latitude: number;
    longitude: number;
    constructor(latitude?: number, longitude?: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}
