import { AbstractUserGuard } from './abstract-user.guard';
import { Injectable } from '@angular/core';
import { UserDto } from '../domain/user.model';
import { SessionService } from '../services/session.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class IsUserAuthenticatedGuard extends AbstractUserGuard {

    constructor(sessionService: SessionService) {
        super(sessionService);
        sessionService.fetchCurrentUser()
            .pipe(tap(user => console.log('connected user', user)))
            .subscribe();
    }

    protected applyCondition(user: UserDto): boolean {
        return user !== null;
    }

    protected afterUserResolving(result: boolean): void {
        if (!result) {
            this.sessionService.login().subscribe();
        }
    }
}
