import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakPublicClientConfigModel } from '../domain/keycloak-public-client-config.model';

@Injectable()
export class ConfigService {

    constructor(private http: HttpClient) { }

    public fetchKeycloakClientConfig(): Observable<KeycloakPublicClientConfigModel> {
        return this.http.get<KeycloakPublicClientConfigModel>('api/config/keycloak');
    }
}
