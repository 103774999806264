import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ExportType, MatTableExporterDirective} from 'mat-table-exporter';
import {DashboardSynthesisDto} from '../../../models/dashboard/dashboard.synthesis.model';
import * as moment from 'moment'

@Component({
    selector: 'dashboard-synthesis-table',
    templateUrl: './dashboard-synthesis-table.component.html',
    styleUrls: ['./dashboard-synthesis-table.component.scss'],
    host: {style: 'width:100%'},
})
export class DashboardSynthesisTableComponent implements OnInit, OnChanges {

    @Input() dashboardSynthesisDtos: DashboardSynthesisDto[];
    @Input() isLoading: boolean;
    public displayedColumns: string[];
    public dateColumns: string[];
    dataSource: MatTableDataSource<DashboardSynthesisDto>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective) exporter: MatTableExporterDirective;

    constructor() {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource<DashboardSynthesisDto>(this.dashboardSynthesisDtos)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.displayedColumns = ['resource', 'total'];
    }

    ngOnChanges(changes: SimpleChanges) {
        this.dataSource = new MatTableDataSource<DashboardSynthesisDto>(this.dashboardSynthesisDtos)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'resource': return item.resource.name;
                default: return item[property];
            }
        };
        this.dataSource.sort = this.sort;

        this.dateColumns = this.dashboardSynthesisDtos
            .flatMap(s => s.distanceDates)
            .map(dd => dd.date)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .map(d => moment(d).format('DD/MM/YY'));
        this.displayedColumns = ['resource'].concat(this.dateColumns).concat(['total']);
    }

    export() {
        this.exporter.exportTable(ExportType.CSV, {fileName: 'export-dashboard-synthesis'});
    }

    getDistanceAtDate(element: DashboardSynthesisDto, date: string) {
        const distanceDate = element.distanceDates
            .find(dd => moment(dd.date).format('DD/MM/YY') === date);
        return distanceDate ? (distanceDate.distance / 1000) : '-';
    }

    getDistanceResource(element: DashboardSynthesisDto): number {
        return element.distanceDates
            .map(dd => dd.distance / 1000)
            .reduce((a, b) => a + b, 0)
    }
}
