import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingDto} from '../../../models/booking.model';
import {BookingService} from '../../../services/booking.service';
import {AlertService, NotificationService, PersonDto} from 'base-lib';
import {PersonFavoriteService} from '../../../services/person-favorite.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {PersonService} from '../../../services/person.service';
import {JourneyStopDto} from '../../../models/journey.model';
import {StopPoint} from '../../../models/stop-point.model';
import {StepType} from '../../../models/step-type';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {SessionService, UserDto} from "auth-lib";

@Component({
    selector: 'booking-description',
    templateUrl: './booking-description.component.html',
    styleUrls: ['./booking-description.component.scss']
})
export class BookingDescriptionComponent implements OnInit {

    @Input() booking: BookingDto;
    @Output() bookingChange: EventEmitter<BookingDto> = new EventEmitter<BookingDto>();
    public person: PersonDto;
    public user: UserDto;

    constructor(private bookingService: BookingService,
                private personService: PersonService,
                private personFavoriteService: PersonFavoriteService,
                private notificationService: NotificationService,
                private alertService: AlertService,
                private router: Router,
                private route: ActivatedRoute,
                private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.personService
            .currentPerson
            .pipe(filter(person => person !== undefined && person !== null))
            .subscribe(person => this.person = person);
        this.sessionService.connectedUser
            .pipe(
                filter(user => user !== undefined && user !== null),
            )
            .subscribe(user => this.user = user);

    }

    public getStopPoint(journeyStopDto: JourneyStopDto, stepType: StepType, shuttle: boolean): StopPoint {
        return StopPoint.createFromStopPoint(journeyStopDto, stepType, shuttle)
    }

    public cancelBooking(booking: BookingDto, event: MouseEvent) {
        this.alertService
            .confirm('notification.confirm.cancelBooking.title', 'notification.confirm.cancelBooking.message')
            .pipe(
                filter(response => response === true),
                switchMap(_ => this.bookingService.cancel(this.booking.id)),
                tap(_ => this.bookingChange.emit(this.booking)),
                tap(_ => booking.state = 'CANCELED')
            )
            .subscribe(
                _ => this.notificationService.success('booking.cancel.ok').subscribe(),
                error => {
                    if (error.status === 403) {
                        this.notificationService.error('booking.cancel.error.notCreator').subscribe()
                    } else {
                        this.notificationService.error('booking.cancel.error').subscribe()
                    }
                }
            );
        event.stopPropagation();
    }

    public isCancelableBooking(booking: BookingDto): boolean {
        return (booking.state === 'WAITING_APPROVAL' || booking.state === 'VALIDATED');
    }

    public removeFavorite(booking: BookingDto, event: MouseEvent) {
        this.personService
            .currentPerson
            .pipe(
                switchMap(currentPerson => this.personFavoriteService.removeFavorite(currentPerson.id, booking.id)),
                tap(_ => this.bookingChange.emit(booking))
            )
            .subscribe(
                ignored => {
                    booking.favorite = !booking.favorite;
                    this.notificationService.success('favorite.removed').subscribe()
                },
                ignored => this.notificationService.error('favorite.error').subscribe()
            );
        event.stopPropagation();
    }

    public addFavorite(booking: BookingDto, event: MouseEvent) {
        this.personService
            .currentPerson
            .pipe(switchMap(currentPerson => this.personFavoriteService.addFavorite(currentPerson.id, booking.id)))
            .subscribe(
                ignored => {
                    booking.favorite = !booking.favorite;
                    this.notificationService.success('favorite.added').subscribe();
                },
                ignored => this.notificationService.error('favorite.error').subscribe()
            );
        event.stopPropagation();
    }

    public isEditableBooking(booking: BookingDto): boolean {
        const [hour = 0, minute = 0, second = 0] = booking.departureStop.departureTime
            .split(':')
            .map(value => parseInt(value, 0));
        return booking.state === 'WAITING_APPROVAL'
            && moment(booking.date).set({hour, minute, second}) >= moment()
            && this.isAdmin();
    }

    public isAdmin() {
        return this.user && this.user.roles.includes('ROLE_OPERATOR');
    }

    public editBooking(booking: BookingDto) {
        this.router.navigate([`..`, booking.id], {relativeTo: this.route});
    }

}
