import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

export type NotificationType = 'question' | 'success' | 'info' | 'warning' | 'error';

@Injectable()
export class AlertService {

    constructor(private translateService: TranslateService) {}

    private swal<T>(type: NotificationType, title: string, message: string, confirmText?: string,
                    cancelText?: string, cancelButton: boolean = false, input: any | null = null,
                    inputPlaceholder: string | undefined = undefined): Observable<T> {
        return Observable.create((observer: Observer<T>) => {
            Swal({
                title: this.translateService.instant(title),
                text: this.translateService.instant(message),
                type,
                buttonsStyling: false,
                showConfirmButton: true,
                showCancelButton: cancelButton,
                confirmButtonClass: 'button primary',
                cancelButtonClass: 'button hollow',
                confirmButtonText: confirmText ? this.translateService.instant(confirmText) : this.translateService.instant('ok'),
                cancelButtonText: cancelText ? this.translateService.instant(cancelText) : this.translateService.instant('cancel'),
                reverseButtons: true,
                focusConfirm: false,
                input,
                inputPlaceholder: inputPlaceholder ? this.translateService.instant(inputPlaceholder) : undefined,
            }).then(result => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }

    public confirm(title: string, message: string, confirmText?: string, cancelText?: string): Observable<boolean> {
        return this.swal('question', title, message, confirmText, cancelText, true);
    }

    public confirmWithTextArea(title: string, message: string, placeholder: string, confirmText?: string, cancelText?: string): Observable<string> {
        return this.swal('question', title, message, confirmText, cancelText, true, 'textarea', placeholder);
    }

    public success(title: string, message: string, confirmText?: string, cancelText?: string): Observable<boolean> {
        return this.swal('success', title, message, confirmText, cancelText);
    }

    public info(title: string, message: string): Observable<boolean> {
        return this.swal('info', title, message);
    }
}
