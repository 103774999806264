import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {Foundation} from 'foundation-sites/js/foundation.core';
import {BookingDto} from '../../../models/booking.model';
import {BookingService} from '../../../services/booking.service';
import {AlertService, NotificationService} from 'base-lib';
import {filter, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'booking-card',
    templateUrl: './booking-card.component.html',
    styleUrls: ['./booking-card.component.scss'],
    host: {class: 'cell shrink grid-y'}
})
export class BookingCardComponent implements AfterViewInit {

    @Input() booking: BookingDto;
    @Output() bookingChange = new EventEmitter<BookingDto>();

    @ViewChild('bookingCardDropdown') public bookingDropdownEl: ElementRef;
    private bookingDropdown: Foundation.Dropdown;

    constructor(private router: Router,
                private bookingService: BookingService,
                private notificationService: NotificationService,
                private alertService: AlertService) {
    }

    ngAfterViewInit(): void {
        this.bookingDropdown = new Foundation.Dropdown($(this.bookingDropdownEl.nativeElement));
    }

    public cancelBooking() {
        this.alertService
            .confirmWithTextArea(
                'notification.confirm.cancelBooking.admin.title',
                'notification.confirm.cancelBooking.admin.message',
                'notification.confirm.cancelBooking.admin.inputPlaceholder',
                'action.validate',
                'cancel'
            )
            .pipe(
                filter(response => response !== undefined),
                switchMap(response => this.bookingService.cancel(this.booking.id, response)),
                tap(_ => this.bookingChange.emit(this.booking))
            )
            .subscribe(
                _ => this.notificationService.success('booking.cancel.ok').subscribe(),
                _ => this.notificationService.error('booking.cancel.error').subscribe()
            );
    }
}
