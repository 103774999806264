import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SettingsDto} from '../models/settings.model';

@Injectable()
export class SettingsService {

    constructor(private http: HttpClient) {}

    public findSettings(): Observable<SettingsDto> {
        return this.http.get<SettingsDto>(`api/setting`);
    }

    public update(settings: SettingsDto): Observable<SettingsDto> {
        return this.http.put<SettingsDto>(`api/setting`, settings);
    }
}
