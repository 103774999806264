import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatchingJourneyDto} from '../models/journey.model';
import {PostalAddressDto} from 'base-lib';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class PlannerService {

    constructor(private http: HttpClient) {}

    public findMatchingJourneys(departure: PostalAddressDto,
                                arrival: PostalAddressDto,
                                dateTime: Date,
                                reducedMobility: boolean,
                                direction: string): Observable<MatchingJourneyDto[]> {

        const searchDateTime = moment(dateTime).format('YYYY-MM-DD[T]HH[:]mm[:]ss');
        const httpParams = new HttpParams()
            .append('departureLatitude', `${departure.geoLocation.latitude}`)
            .append('departureLongitude', `${departure.geoLocation.longitude}`)
            .append('departureName', `${departure.address} ${departure.city}`)
            .append('departureType', departure.type)
            .append('arrivalLatitude', `${arrival.geoLocation.latitude}`)
            .append('arrivalLongitude', `${arrival.geoLocation.longitude}`)
            .append('arrivalName', `${arrival.address} ${arrival.city}`)
            .append('arrivalType', arrival.type)
            .append('dateTime', searchDateTime)
            .append('direction', direction)
            .append('reducedMobility', String(reducedMobility));
        return Observable.create(subscriber => {
            this.http.get<MatchingJourneyDto[]>('api/planner/journey', {params: httpParams})
                .pipe(catchError(e => subscriber.error(e)))
                .pipe(map(dtos => plainToClass(MatchingJourneyDto, dtos)))
                .subscribe(results => {
                    subscriber.next(results);
                    subscriber.complete();
                });
        });
    }

    public fetchJourneysAvailabilities(journeyId: string[]): Observable<string[]> {
        const httpParams = new HttpParams()
            .append('journeyIds', journeyId.join(','));
        return Observable.create(subscriber => {
            this.http.get<string[]>('api/planner/journey/available', {params: httpParams})
                .pipe(catchError(e => subscriber.error(e)))
                .subscribe(results => {
                    subscriber.next(results);
                    subscriber.complete();
                });
        });
    }
}
