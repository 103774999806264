import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserDto} from '../domain/user.model';
import {SessionService} from '../services/session.service';
import {tap} from 'rxjs/operators';
import {Role} from '../domain/role.model';

@Directive({
    selector: '[hiddenForRoles]'
})
export class HiddenForRolesDirective implements OnInit {

    private roles: Role[];
    private user: UserDto;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private sessionService: SessionService) {
    }

    @Input() set hiddenForRoles(roles: Role[]) {
        this.roles = roles;
    }

    ngOnInit(): void {
        this.sessionService.connectedUser
            .pipe(tap(user => this.user = user))
            .subscribe(user => this.refresh());
    }

    private refresh() {
        this.show();
        if (this.user && this.roles) {
            if (this.intersectRoles(this.user.roles, this.roles).length > 0) {
                this.hide();
            }
        }
    }

    private show(): void {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    private hide(): void {
        this.viewContainer.clear();
    }

    private intersectRoles(roles: Role[], roles2: Role[]): Role[] {
        return roles.filter(role => roles2.indexOf(role) >= 0);
    }
}
