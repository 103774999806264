import {JourneyStopDto} from './journey.model';
import {PostalAddressType} from 'base-lib';
import { StepType } from './step-type';

export class StopPoint {

    stopName: string;
    type: PostalAddressType;
    guaranteed: StopPointGuaranttedType;
    time: string;
    stepType: StepType;

    public static createFromStopPoint(stopPoint: JourneyStopDto, stepType: StepType, shuttle: boolean): StopPoint {
        const stopPointCreator = new StopPoint();
        stopPointCreator.stepType = stepType;
        stopPointCreator.stopName = stopPoint.stopAreaName;
        stopPointCreator.time = stepType === 'DEPARTURE' ? stopPoint.departureTime : stopPoint.arrivalTime;
        stopPointCreator.guaranteed = shuttle ? 'SHUTTLE' : stopPointCreator.guaranteed = stopPoint.exactTime ? 'APPROXIMATE' : 'NO';
        stopPointCreator.type = stopPoint.type;
        return stopPointCreator;
    }

}

export type StopPointGuaranttedType = 'SHUTTLE' | 'APPROXIMATE' | 'NO';
