import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserDto} from 'auth-lib';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) {}

    public findOperators(): Observable<UserDto[]> {
        return this.http.get<UserDto[]>('api/operator');
    }

    public findOperator(id: string): Observable<UserDto> {
        return this.http.get<UserDto>(`api/operator/${id}`);
    }

    public createOrUpdateOperator(user: UserDto): Observable<UserDto> {
        return this.http.post<UserDto>('api/operator', user);
    }

    public findTransporters(): Observable<UserDto[]> {
        return this.http.get<UserDto[]>('api/transporter');
    }

    public findTransporter(id: string): Observable<UserDto> {
        return this.http.get<UserDto>(`api/transporter/${id}`);
    }

    public createOrUpdateTransporter(user: UserDto): Observable<UserDto> {
        if (user.id) {
            return this.http.patch<UserDto>(`api/transporter/${user.id}`, user);
        } else {
            return this.http.post<UserDto>('api/transporter', user);
        }
    }
}
