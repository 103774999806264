import { Directive, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as jQuery_ from 'jquery';

const jQuery = jQuery_;

@Directive({
    selector: '[required]'
})
export class RequiredFormElementDirective {

    constructor(@Self() @Optional() private ngModel: NgModel, private elt: ElementRef) {}

    @Input()
    public set required(required: boolean) {
        if (this.ngModel) {
            this.starOnLabel(required !== false);
        }
    }

    private starOnLabel(required: boolean): void {
        const nativeElement = jQuery(this.elt.nativeElement);
        if (!this.ngModel.isDisabled) {
            const children = nativeElement
                .parent('label, div.likeLabel')
                .children('[data-label]');
            if (required) {
                children.addClass('_required');
            } else {
                children.removeClass('_required');
            }
        }
    }
}
