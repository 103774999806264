import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TransportResourceDto} from '../models/transport-resource.model';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class TransportResourceService {

    constructor(private http: HttpClient) {}

    public findAll(): Observable<TransportResourceDto[]> {
        return this.http
            .get<TransportResourceDto[]>('api/transportResource')
            .pipe(
                map(dtos => plainToClass(TransportResourceDto, dtos))
            );
    }

    public find(id: string): Observable<TransportResourceDto> {
        return this.http
            .get<TransportResourceDto>(`api/transportResource/${id}`)
            .pipe(map(dto => plainToClass(TransportResourceDto, dto)));
    }

    public create(transportResource: TransportResourceDto): Observable<TransportResourceDto> {
        return this.http
            .post<TransportResourceDto>(`api/transportResource`, transportResource)
            .pipe(map(dto => plainToClass(TransportResourceDto, dto)));
    }

    public update(id: string, transportResource: TransportResourceDto): Observable<TransportResourceDto> {
        return this.http
            .put<TransportResourceDto>(`api/transportResource/${id}`, transportResource)
            .pipe(map(dto => plainToClass(TransportResourceDto, dto)));
    }
}
