import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ShuttleDto} from '../models/shuttle.model';
import {ShuttleEditionSearchCriteria} from '../models/shuttle-edition-search.model';
import {EnrichedJourneyDto} from '../models/shuttle-journey.model';
import {ShuttleSearchCriteria} from '../models/shuttle-search.model';

@Injectable()
export class ShuttleService {

    constructor(private http: HttpClient) {}

    public findByVehicleJourney(vehicleJourneyId: string) {
        return this.http.get<ShuttleDto>(`api/shuttle/${vehicleJourneyId}`);
    }

    public findAll(criteria: ShuttleSearchCriteria) {
        return this.http.get<ShuttleDto[]>(`api/shuttle`);
    }

    public findAllVehicleJourneys(criteria: ShuttleEditionSearchCriteria) {
        return this.http.get<EnrichedJourneyDto[]>('api/shuttle/vehicleJourneys', {params: criteria.toSearchParams()});
    }

    public create(vehicleJourneyId: string) {
        return this.http.post<ShuttleDto>(`api/shuttle/${vehicleJourneyId}`, {});
    }

    public delete(vehicleJourneyId: string) {
        return this.http.delete<ShuttleDto>(`api/shuttle/${vehicleJourneyId}`, {});
    }
}
