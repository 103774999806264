import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http/src/request';
import { HttpHandler } from '@angular/common/http/src/backend';
import { HttpEvent } from '@angular/common/http/src/response';
import { Observable, of } from 'rxjs';
import { KeycloakService } from '../services/keycloak.service';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {

    constructor(private keycloakService: KeycloakService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.keycloakService.isLoggedIn()) {
            return this.keycloakService
                .getToken()
                .pipe(
                    mergeMap((token) => {
                        if (token) {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                        }
                        return next.handle(request);
                    })
                    // catchError(e => {
                    //     this.keycloakService.login().subscribe();
                        // return of(null);
                    // })
                );
        }
        return next.handle(request);
    }
}
