import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment_ from 'moment';
import {Moment} from 'moment';
import {CalendarValue, DayCalendarComponent, IDayCalendarConfig, SingleCalendarValue} from 'ng2-date-picker';
import * as lodash_ from 'lodash';

const moment = moment_;
const _ = lodash_;

@Component({
    selector: 'multi-date-picker',
    templateUrl: './multi-date-picker.component.html',
    styles: [':host {display: block}'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiDatePickerComponent),
            multi: true
        }
    ]
})
export class MultiDatePickerComponent implements ControlValueAccessor {

    public config: IDayCalendarConfig = {
        firstDayOfWeek: 'mo',
        unSelectOnClick: true,
        min: this.minDate,
        max: this.maxDate,
        locale: 'fr',
        format: 'DD/MM/YYYY',
        allowMultiSelect: true,
        showNearMonthDays: false,
        enableMonthSelector: false,
        isDayDisabledCallback: date => this._availableDates.find(d => d.isSame(date, 'date')) === undefined
    };
    @ViewChild(DayCalendarComponent) calendar: DayCalendarComponent;
    private onNgChange: (instant: string[]) => void;
    private onNgTouched: () => void;
    private _maxDate: string;
    private _minDate: string;
    private _availableDates: Moment[] = [];
    @Input() public disabled: boolean = false;
    public realDates: Moment[];

    @Input()
    public get maxDate(): string {
        return this._maxDate;
    }

    public set maxDate(maxDate: string) {
        this._maxDate = maxDate;
        this.config = {
            ...this.config,
            max: maxDate
        };
    }

    @Input()
    public get minDate(): string {
        return this._minDate;
    }

    public set minDate(minDate: string) {
        this._minDate = minDate;
        this.config = {
            ...this.config,
            min: minDate
        };
    }

    @Input()
    public set availableDates(dates: string[]) {
        this._availableDates = (dates || []).map(d => moment(d));
    }

    public onChange(calendarValue: CalendarValue): void {
        if (_.isArray(calendarValue)) {
            const values = (calendarValue as any[]).map(value => {
                const date: Moment = this.computeDate(value);
                if (date === undefined) {
                    return undefined;
                } else {
                    return date.toDate().toISOString();
                }
            });
            this.onNgChange(values);
        }
    }

    private computeDate(date: SingleCalendarValue): Moment {
        if (moment.isMoment(date)) {
            return date as Moment;
        } else {
            const m = moment(date, ['DD-MM-YYYY', 'DD/MM/YYYY']);
            if (m.isValid()) {
                return m;
            } else {
                return undefined;
            }
        }
    }

    public writeValue(obj: string[]): void {
        if (obj !== null) {
            this.realDates = obj === undefined ? undefined : obj.map(d => moment(d));
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }
}
