import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiDatePickerComponent } from '../multi-date-picker/multi-date-picker.component';
import { INavEvent } from 'ng2-date-picker/common/models/navigation-event.model';
import * as moment_ from 'moment';
import { Moment } from 'moment';

const moment = moment_;

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiDateCalendarsPickerComponent),
    multi: true
};

@Component({
    selector: 'multi-date-calendars-picker',
    templateUrl: './multi-date-calendars-picker.component.html',
    styleUrls: ['./multi-date-calendars-picker.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class MultiDateCalendarsPickerComponent implements AfterViewInit, ControlValueAccessor {

    @Input() availableDates: string[];

    public month1Dates: string[] = [];
    public month2Dates: string[] = [];

    private internalMonth1Dates: string[] = [];
    private internalMonth2Dates: string[] = [];

    @ViewChild('month1') month1DatePicker: MultiDatePickerComponent;
    @ViewChild('month2') month2DatePicker: MultiDatePickerComponent;

    private month1: Moment = moment();
    private month2: Moment = moment().add(1, 'month');

    ngAfterViewInit(): void {
        this.month1DatePicker.calendar.moveCalendarTo(this.month1);
        this.month2DatePicker.calendar.moveCalendarTo(this.month2);
        this.month1DatePicker.calendar.onLeftNav.subscribe((e: INavEvent) => this.onMonth1Change(e.from, e.to));
        this.month1DatePicker.calendar.onRightNav.subscribe((e: INavEvent) => this.onMonth1Change(e.from, e.to));
        this.month2DatePicker.calendar.onLeftNav.subscribe((e: INavEvent) => this.onMonth2Change(e.from, e.to));
        this.month2DatePicker.calendar.onRightNav.subscribe((e: INavEvent) => this.onMonth2Change(e.from, e.to));
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        if (obj) {
            this.month1Dates = this.internalMonth1Dates = obj.filter(d => moment(d).isSame(this.month1, 'month'));
            this.month2Dates = this.internalMonth2Dates = obj.filter(d => moment(d).isSame(this.month2, 'month'));
        }
    }

    private onMonth1Change(from: Moment, to: Moment): void {
        this.month2DatePicker.calendar.api.moveCalendarTo(to.add(1, 'month'));
    }

    private onMonth2Change(from: Moment, to: Moment): void {
        this.month1DatePicker.calendar.api.moveCalendarTo(to.subtract(1, 'month'));
    }

    public onMonth1DatesChange(dates: string[]): void {
        this.internalMonth1Dates = dates;
        this.onChange([...dates, ...this.internalMonth2Dates]);
    }

    public onMonth2DatesChange(dates: string[]): void {
        this.internalMonth2Dates = dates;
        this.onChange([...this.internalMonth1Dates, ...dates]);
    }
}
