import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment_ from 'moment';
import { Moment } from 'moment';
import { IDatePickerConfig, ISelectionEvent, SingleCalendarValue } from 'ng2-date-picker';

const moment = moment_;

@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.component.html',
    styles: [':host {display: block}'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ]
})
export class TimePickerComponent implements ControlValueAccessor {

    public config: IDatePickerConfig = {
        showSeconds: false,
        showTwentyFourHours: true,
        format: 'HH:mm'
    };
    private onNgChange: (instant: Date) => void;
    private onNgTouched: () => void;

    @Input()
    public disabled: boolean = false;
    public realTime: Moment;

    public onChange(event: ISelectionEvent): void {
        const date: Moment = this.computeDate(event.date);
        if (date === undefined) {
            this.onNgChange(undefined);
        } else {
            this.onNgChange(date.toDate());
        }
    }

    private computeDate(date: SingleCalendarValue): Moment {
        if (moment.isMoment(date)) {
            return date as Moment;
        } else {
            const m = moment(date, ['HH:mm']);
            if (m.isValid()) {
                return m;
            } else {
                return undefined;
            }
        }
    }

    public onOpen(): void {
        this.onNgTouched();
    }

    public writeValue(obj: string): void {
        if (obj !== null) {
            this.realTime = obj === undefined ? undefined : moment(obj);
        }
    }

    public registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }
}
