import { Observable } from 'rxjs';

export class Service {

    protected handleError(error: Response | any) {
        console.error(error);
        return Observable.create(error);
    }
}

