import {PersonDto, Selectable} from 'base-lib';
import * as moment from 'moment';
import {BookingState} from './booking.model';

export class Passenger implements Selectable {

    id: string;
    firstName: string;
    lastName: string;
    birthDate: Date;
    reducedMobility: boolean;
    bike: boolean;
    personId: string;
    passengerSelect: Passenger;
    state: BookingState;

    constructor(firstName: string,
                lastName: string,
                birthDate: Date,
                reducedMobility: boolean,
                personId: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.reducedMobility = reducedMobility;
        this.bike = false;
        this.personId = personId;
        this.id = personId;
        this.birthDate = birthDate;
    }

    public static fromPerson(person: PersonDto): Passenger {
        return new Passenger(person.firstName, person.lastName, person.birthDate, person.reducedMobility, person.id)
    }

    public get _id(): string {
        return this.id;
    }

    public get label(): string {
        return this.personId ? `${this.firstName} ${this.lastName}` : 'person.chaperon';
    }

    public get computeAge(): number {
        return moment().diff(moment(this.birthDate), 'year');
    }
}
