import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {PersonDto, PostalAddressDto} from 'base-lib';
import {PersonService} from '../../../services/person.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PersonFavoriteService} from '../../../services/person-favorite.service';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FavoriteSelectComponent),
    multi: true
};

@Component({
    selector: 'favorite-select',
    templateUrl: './favorite-select.component.html',
    styleUrls: ['./favorite-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class FavoriteSelectComponent implements OnInit, ControlValueAccessor {

    @Input() person: PersonDto;

    public favorites: PostalAddressDto[] = [];
    public favorite: PostalAddressDto;
    private disabled: boolean;

    constructor(private personService: PersonService,
                private personFavoriteService: PersonFavoriteService) {}

    ngOnInit() {
        this.personFavoriteService
            .findFavorites(this.person.id, true)
            .subscribe(favorites => this.favorites = favorites);
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.favorite = obj;
    }

    public submit(result: PostalAddressDto): void {
        this.writeValue(result);
        this.onChange(result);
    }

    public isSelected(favorite: PostalAddressDto): boolean {
        return favorite && this.favorite
            && favorite.geoLocation.longitude === this.favorite.geoLocation.longitude
            && favorite.geoLocation.latitude === this.favorite.geoLocation.latitude;
    }
}
