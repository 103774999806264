import { AfterViewInit, Component, forwardRef, HostListener, Input } from '@angular/core';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { FileRef } from '../../../models/file-ref.model';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WebcamSnapshotComponent),
    multi: true
};

@Component({
    selector: 'webcam-snapshot',
    templateUrl: './webcam-snapshot.component.html',
    styleUrls: ['./webcam-snapshot.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class WebcamSnapshotComponent implements ControlValueAccessor, AfterViewInit {

    @Input() parent: HTMLElement;

    // public videoOptions: MediaTrackConstraints = {width: {ideal: 1024}, height: {ideal: 576}};
    public errors: WebcamInitError[] = [];
    private trigger: Subject<void> = new Subject<void>();
    public width: number = 800;
    public height: number = 384;

    ngAfterViewInit() {
        this.computeWebcamContainerSize();
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    private computeWebcamContainerSize(): void {
        if (this.parent) {
            this.height = this.parent.clientHeight;
            this.width = this.parent.clientWidth;
        }
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public handleImage(image: WebcamImage): void {
        const file = this.dataURLtoFile(image.imageAsDataUrl, `picture_${new Date().getTime()}`);
        this.onChange(new FileRef(file));
    }

    private dataURLtoFile(dataUrl: string, filename: string): File {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event?: Event) {
        this.computeWebcamContainerSize();
    }
}
