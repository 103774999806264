import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InvalidFeedbackComponent),
    multi: true
};

@Component({
    selector: 'invalid-feedback',
    templateUrl: './invalid-feedback.component.html',
    styleUrls: ['./invalid-feedback.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class InvalidFeedbackComponent implements ControlValueAccessor {

    public disabled: boolean;
    public field: NgModel;

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.field = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public findErrors(): string[] {
        if (this.field && this.field.dirty && this.field.invalid) {
            return Object.keys(this.field.errors);
        }
        return [];
    }
}
