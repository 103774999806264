import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BookingAssignmentSearchCriteria} from '../models/booking-assignment-search.criteria';
import {BookingAssignmentDto} from '../models/booking-assignment.model';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class BookingAssignmentService {

    constructor(private http: HttpClient) {}

    public assignBookingToResource(bookingId: string, resourceId: string): Observable<any> {
        return this.http.post<any>(`api/booking/${bookingId}/resource/${resourceId}`, {});
    }

    public find(criteria: BookingAssignmentSearchCriteria): Observable<BookingAssignmentDto[]> {
        const params = criteria.toSearchParams();
        return this.http
            .get<BookingAssignmentDto[]>(`api/booking/assignment`, {params})
            .pipe(map(dtos => plainToClass(BookingAssignmentDto, dtos)));
    }
}
