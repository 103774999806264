import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements ControlValueAccessor {

    private onNgChange: (value: boolean) => void;
    private onNgTouched: () => void;

    public disabled: boolean;
    public checked: boolean;

    @Input() public label: string;
    @Input() public required: string;
    @Input() public alone: boolean;

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: boolean): void {
        this.checked = obj;
    }

    public onCheckedChange(checked: boolean): void {
        this.onNgChange(checked);
        this.checked = checked;
    }
}
