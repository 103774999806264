import {PersonDto, PostalAddressDto} from 'base-lib';
import {Passenger} from './passenger.model';
import {JourneyStopDto, MatchingJourneyDto} from './journey.model';
import {Type} from 'class-transformer';

export class BookingDto {

    id: string;
    date: Date;
    state: BookingState;
    departureAddress: PostalAddressDto;
    departureStop: JourneyStopDto;
    arrivalAddress: PostalAddressDto;
    arrivalStop: JourneyStopDto;
    passengers: Passenger[] = [];
    vehicleJourneyId: string;
    publicNumber: string;
    creationDate: Date;
    @Type(() => PersonDto)
    creatorPerson: PersonDto;
    favorite: boolean;
    shuttle: boolean;

    passengersWithReducedMobilityCount: number;
    passengersWithBikeCount: number;
    connectionTransport: boolean;

    public static create(id: string,
                         date: Date,
                         departureAddress: PostalAddressDto,
                         arrivalAddress: PostalAddressDto,
                         passengers: Passenger[],
                         journey: MatchingJourneyDto,
                         connectionTransport: boolean): BookingDto {
        const bookingDto = new BookingDto();
        bookingDto.id = id;
        bookingDto.departureAddress = departureAddress;
        bookingDto.arrivalAddress = arrivalAddress;
        bookingDto.date = date;
        bookingDto.vehicleJourneyId = journey.journey.id;
        bookingDto.departureStop = journey.departureStop;
        bookingDto.arrivalStop = journey.arrivalStop;
        bookingDto.passengers = passengers;
        bookingDto.connectionTransport = connectionTransport;
        return bookingDto;
    }
}

export type BookingState = 'WAITING_APPROVAL' | 'VALIDATED' | 'CANCELED' | 'FINISHED' | 'MISSED';
