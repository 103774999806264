import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from '../../components/components.module';

const views: any[] = [
    AppComponent
];

@NgModule({
    declarations: [views],
    imports: [
        // 3rd party modules
        RouterModule,
        CommonModule,
        FormsModule,
        TranslateModule,

        // app modules
        ComponentsModule,
        AppRoutingModule
    ]
})
export class AppModule {
}
