import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TransportResourceDto} from '../../../models/transport-resource.model';
import {LineDto} from '../../../models/line.model';
import {LineService} from '../../../services/line.service';
import {TransportResourceService} from '../../../services/transport-resource.service';

@Component({
    selector: 'transport-resource-list',
    templateUrl: './transport-resource-list.component.html',
    styleUrls: ['./transport-resource-list.component.scss'],
    host: {class: 'cell auto grid-y'}
})
export class TransportResourceListComponent implements OnInit {

    public transportResources: TransportResourceDto[];
    @Output() selected: EventEmitter<TransportResourceDto> = new EventEmitter();
    public lines: LineDto[];

    constructor(private lineService: LineService,
                private transportResourceService: TransportResourceService) {
    }

    ngOnInit(): void {
        this.transportResourceService
            .findAll()
            .subscribe(transportResources => {
                this.transportResources = transportResources;
                this.lineService
                    .findAllLines()
                    .subscribe(lines => {
                        this.lines = lines
                        this.initTransportResourceLines();
                    });
            });
    }


    private initTransportResourceLines() {
        if (this.lines) {
            this.transportResources = this.transportResources
                .map(transportResource => {
                        transportResource.lines = transportResource.lines
                            .map(line => this.lines.find(l => l.id === line.id))
                        return transportResource;
                    }
                );
        }
    }

    public mapLines(lines: LineDto[]) {
        return lines.map(line => line.name).join(', ')
    }

}
