import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable()
export class BusinessErrorHttpInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(
                catchError(err => this.handleError(err))
            )
    }

    private handleError(err: any) {
        if (err.error && err.error.key) {
            this.notificationService.error(err.error.key, err.error.context).subscribe();
        }
        return throwError(err);
    }
}
