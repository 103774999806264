import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JourneyBookingDto} from '../models/journey-booking.model';

@Injectable()
export class JourneyBookingService {

    constructor(private http: HttpClient) {
    }

    public updateJourneyBooking(journeyBooking: JourneyBookingDto): Observable<JourneyBookingDto> {
        return this.http.post<JourneyBookingDto>('api/journey-booking', journeyBooking)
    }

}
