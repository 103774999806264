import {Role} from './role.model';
import {CompanyDto} from './company.model';

export class UserDto {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: Role[];
    groups: string[];
    company: CompanyDto;
}
