import {PostalAddressDto} from './postal-address.model';

export class PersonDto {

    id?: string;
    subscriberNumber?: string;
    gender: PersonGender;
    firstName: string;
    lastName: string;
    birthDate: Date;
    email: string;
    state: PersonState;
    kind: string;
    phoneNumber: string;
    cellPhoneNumber: string;
    postalAddresses: PostalAddressDto[];
    reducedMobility: boolean;
    reducedMobilityDescription: string;
    pictureBinaryDataId: string;

    constructor() {
        const postalAddressDto = new PostalAddressDto();
        postalAddressDto.principal = true;
        this.postalAddresses = [postalAddressDto];
    }

    public get label(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}

export type PersonGender = 'MALE' | 'FEMALE' | 'OTHER';
export type PersonState = 'WAITING_APPROVAL' | 'ACTIVE' | 'ARCHIVED';
