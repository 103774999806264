import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserDto } from '../domain/user.model';
import { SessionService } from '../services/session.service';
import { tap } from 'rxjs/operators';
import { Role } from '../domain/role.model';

@Directive({
    selector: '[shownForRoles]'
})
export class ShownForRolesDirective implements OnInit {

    private roles: Role[];
    private user: UserDto;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private sessionService: SessionService) {
    }

    @Input() set shownForRoles(roles: Role[]) {
        this.roles = roles;
        this.refresh();
    }

    ngOnInit(): void {
        this.sessionService.connectedUser
            .pipe(tap(user => this.user = user))
            .subscribe(user => this.refresh());
    }

    private refresh() {
        this.hide();
        if (this.user && this.roles) {
            if (this.getRequestedRoles(this.roles, this.user.roles).length > 0
                && this.getUnrequestedRoles(this.roles, this.user.roles).length === 0) {
                this.show();
            }
        }
    }

    private show(): void {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    private hide(): void {
        this.viewContainer.clear();
    }

    private getRequestedRoles(roles: string[], userRoles: string[]): string[] {
        return roles
            .filter(role => role[0] !== '!')
            .filter(role => userRoles.indexOf(role) >= 0);
    }

    private getUnrequestedRoles(roles: string[], userRoles: string[]): string[] {
        return roles
            .filter(role => role[0] === '!')
            .filter(role => userRoles.indexOf(role.substring(1)) >= 0);
    }
}
