import { Selectable } from 'base-lib';

export class LineDto implements Selectable {

    id: string;
    name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    public get _id(): string {
        return this.id;
    };

    public get label(): string {
        return this.name;
    }
}
