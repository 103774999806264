import {Injectable} from '@angular/core';
import {SessionService} from '../services/session.service';
import {Router} from '@angular/router';
import {AbstractUserGuard} from './abstract-user.guard';
import {UserDto} from '../domain/user.model';

@Injectable()
export class TransporterRedirectionGuard extends AbstractUserGuard {

    constructor(sessionService: SessionService, private router: Router) {
        super(sessionService);
    }

    protected applyCondition(user: UserDto): boolean {
        if (user !== null && user.roles && user.roles.indexOf('ROLE_TRANSPORTER') >= 0) {
            this.router.navigate(['/admin']);
        }
        return true;
    }
}
