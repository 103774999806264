import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { UrbanAreaDto } from '../models/urban-area.model';
import { PostalAddressDto } from 'base-lib';

@Injectable()
export class UrbanAreaService {

    constructor(private http: HttpClient) {}

    private readonly baseUrl: string = 'api/urbanArea';

    public findAll(): Observable<UrbanAreaDto[]> {
        return this.http
            .get<UrbanAreaDto[]>(this.baseUrl)
            .pipe(
                map(dtos => plainToClass(UrbanAreaDto, dtos)),
            );
    }

    public findUrbanAreaPostalAddresses(id: string): Observable<PostalAddressDto[]> {
        return this.http
            .get<PostalAddressDto[]>(`${this.baseUrl}/${id}/stopAreas/postalAddress`)
            .pipe(map(dto => plainToClass(PostalAddressDto, dto)));
    }
}
