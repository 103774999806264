import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { PersonDto } from 'base-lib';
import { catchError, map, tap } from 'rxjs/operators';
import { BookingDto } from '../models/booking.model';
import { plainToClass } from 'class-transformer';
import { Service } from './service';
import * as moment from 'moment';
import { BookingSearchCriteria } from '../models/booking-search.model';

@Injectable()
export class PersonService extends Service {

    public currentPerson: Subject<PersonDto> = new BehaviorSubject(undefined);

    constructor(private http: HttpClient) {
        super();
    }

    public findCurrentPerson(): Observable<PersonDto> {
        return this.http
            .get<PersonDto>(`api/person/current`)
            .pipe(
                catchError(e => of(null)),
                tap(person => this.currentPerson.next(person)),
            );
    }

    public findPerson(id: string): Observable<PersonDto> {
        return this.http.get<PersonDto>(`api/person/${id}`);
    }

    public findPassengers(personId: string): Observable<PersonDto[]> {
        const httpParams = new HttpParams().append('includeHimself', 'true');
        return this.http.get<PersonDto[]>(`api/person/${personId}/passengers`, {params: httpParams})
    }

    public findBookings(personId: string, criteria: BookingSearchCriteria): Observable<BookingDto[]> {
        if (criteria.from) {
            criteria.from = moment(criteria.from).format('YYYY-MM-DD')
        }
        if (criteria.to) {
            criteria.to = moment(criteria.to).format('YYYY-MM-DD')
        }
        const params = criteria.toSearchParams();
        return this.http
            .get<BookingDto[]>(`api/person/${personId}/bookings`, {params})
            .pipe(
                map(dtos => plainToClass(BookingDto, dtos), this.handleError)
            );
    }
}
