import { Component } from '@angular/core';

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {class: 'grid-y cell auto'}
})
export class AppComponent {

}
