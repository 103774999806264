import { Component, Input } from '@angular/core';
import { StopPoint } from '../../../models/stop-point.model';

@Component({
    selector: 'stop-point-view',
    templateUrl: './stop-point-view.component.html',
    styleUrls: ['./stop-point-view.component.scss']
})
export class StopPointViewComponent {

    @Input() stopPoint: StopPoint;

}
