import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryService } from '../../../services/country.service';
import { Selectable } from '../../../models/selectable.model';
import { filter, map, tap } from 'rxjs/operators';
import { CountryDto } from '../../../models/country.model';
import { Regex } from '../../../models/regex';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneNumberInputComponent),
    multi: true
};

@Component({
    selector: 'phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss'],
    host: {class: 'grid-x grid-frame grid-padding-x'},
    providers: [VALUE_ACCESSOR]
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor {

    public disabled: boolean;
    public dialCode: Selectable;
    public _phoneNumber: string;
    public dialCodes: Selectable[] = [];
    private spacePattern: string = Regex.space;

    constructor(private countryService: CountryService) {}

    ngOnInit() {
        this.countryService
            .fetchCountries()
            .pipe(
                map(countries => countries.filter(country => country.dialCode)),
                map(countries => countries.map(code => this.buildSelectable(code))),
                tap(dialCode => this.dialCode = dialCode[0])
            )
            .subscribe(dialCodes => this.dialCodes = dialCodes);
    }

    @Input()
    public set phoneNumber(phoneNumber: string) {
        this._phoneNumber = phoneNumber;
        this.selectDialCode();
        this.eraseDialCodeInPhoneNumber();
    }

    public get phoneNumber() {
        return this._phoneNumber;
    }

    private removeSpace(text: string) {
        return text ? text.replace(this.spacePattern, '') : text;
    }

    onChange = (_: any) => {
    };

    onTouched = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.phoneNumber = obj;
    }

    private buildSelectable(country: CountryDto): Selectable {
        return {_id: country.dialCode, label: `${country.dialCode} (${country.code})`};
    }

    private eraseDialCodeInPhoneNumber() {
        if (this._phoneNumber) {
            this.dialCodes.forEach(dialCode => this._phoneNumber = this.restorePhoneNumber(dialCode));
        }
    }

    private restorePhoneNumber(dialCode): string {
        return this.removeSpace(this._phoneNumber).replace(this.removeSpace(dialCode._id), '');
    }

    private restoreDialCode(): Selectable[] {
        return this.dialCodes
            .filter(dialCode => this.removeSpace(this._phoneNumber).startsWith(this.removeSpace(dialCode._id)));
    }

    private selectDialCode() {
        if (this._phoneNumber) {
            const dialCodes = this.restoreDialCode();
            if (dialCodes.length) {
                this.dialCode = dialCodes[0];
            }
        }
    }

    public onNgChange() {
        this.cleanPhoneNumber();
        this.eraseDialCodeInPhoneNumber();
        if (this.dialCode && this.phoneNumber && /^\d+$/.test(this.phoneNumber)) {
            this.onChange(`${this.dialCode._id}${this.phoneNumber}`);
        } else {
            this.onChange(null);
        }
    }

    private cleanPhoneNumber() {
        if (this._phoneNumber) {
            this._phoneNumber = this.removeSpace(this._phoneNumber);
            if (this.dialCode && this.removeSpace(this.dialCode._id) === '+33') {
                this._phoneNumber = this._phoneNumber.replace(/^(?!00[^0])0/, '');
                this._phoneNumber = this._phoneNumber.replace('.', '');
                this._phoneNumber = this._phoneNumber.replace(' ', '');
            }
        }
    }
}
